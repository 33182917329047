import axios from "axios";
import { Button } from "components/form/Button";
import React, { useState } from "react";
import CreatableSelect from "react-select";
import { toast } from "react-toastify";
const roleOp = [
  "dashboard",
  "agent",
  "user",
  "balance-request",
  "from-request",
  "payment-sheet",
  "email-setup",
  "report",
  "attendance",
  "accounts",
  "accounts",
  "admin",
  "settings",
];

const AddNewAdmin = ({ close }) => {
  const [role, SetRole] = useState(["dashboard", "agent", "user", "settings", "report"]);
  const [username, setUsername] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [password, setPassword] = useState();

  // create
  const Save = async () => {
    try {
      const res = await toast.promise(
        axios.post("/api/admin/create-admin", {
          username,
          email,
          phone,
          role: JSON.stringify(role),
          address,
          password,
        }),
        {
          error: "ops sometimes is wrong",
          pending: "please wait...",
          success: "user saved successfully",
        }
      );
      close(false);
    } catch (error) {
      console.log("🚀 ~ Save ~ error:", error);
    }
  };

  return (
    <div className="fixed left-0 top-0 z-[300]  h-screen w-full  overflow-y-auto bg-lightPrimary/20 pt-10 backdrop-blur-sm ">
      <div className="mx-auto w-full max-w-[400px] bg-white p-3 shadow-md">
        <div>
          <h1 className="w-full text-center text-2xl">Add New Admin</h1>
        </div>
        <div>
          {/* userName */}
          <div className="flex flex-col gap-1">
            <label title={"username"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
              Username
            </label>
            <input
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              className={
                "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
              }
              placeholder={"username here"}
              type={"text"}
            />
          </div>
          {/* Phone */}
          <div className="flex flex-col gap-1">
            <label title={"Phone"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
              Phone
            </label>
            <input
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              className={
                "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
              }
              placeholder={"Phone here"}
              type={"text"}
            />
          </div>
          {/* Email */}
          <div className="flex flex-col gap-1">
            <label title={"Email"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
              Email
            </label>
            <input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className={
                "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
              }
              placeholder={"Email here"}
              type={"text"}
            />
          </div>
          {/* Address */}
          <div className="flex flex-col gap-1">
            <label title={"Address"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
              Address
            </label>
            <input
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              className={
                "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
              }
              placeholder={"Address here"}
              type={"text"}
            />
          </div>
          {/* Password */}
          <div className="flex flex-col gap-1">
            <label title={"Password"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
              Password
            </label>
            <input
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              className={
                "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
              }
              placeholder={"Password here"}
              type={"text"}
            />
          </div>
          {/* role  */}
          <div className="flex flex-col gap-1">
            <label title={"User Role"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
              User Role
            </label>
            <CreatableSelect
              isMulti={true}
              options={roleOp.map((e) => ({ label: e, value: e }))}
              onChange={(e) => {
                SetRole(e.map((x) => x.value));
              }}
              defaultValue={role.map((e) => ({ label: e, value: e }))}
              menuPosition="fixed"
              styles={{
                control: (styles, state) => ({
                  ...styles,
                  margin: 0,
                  padding: 2,
                  backgroundColor: "rgb(248 249 250)",
                  borderColor: false
                    ? "rgb(245 57 57 / 0.5)"
                    : state.isFocused
                    ? "rgb(59 130 246 / 0.5)"
                    : state.isDisabled
                    ? "rgb(233 227 255)"
                    : "rgb(192 184 254)",
                  opacity: state.isDisabled ? "0.5" : 1,
                  borderWidth: 1.5,
                  "&:hover": {
                    borderColor: "none",
                  },
                  boxShadow: state.isFocused
                    ? false
                      ? "0 0 0 1px rgb(245 57 57 / 0.5)"
                      : "0 0 0 1px rgb(59 130 246 / 0.5)"
                    : "none",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  fontSize: "0.875rem",
                  color: "#adb5bd",
                  letterSpacing: "0.3px",
                  userSelect: "none",
                }),
                valueContainer: (styles) => ({
                  ...styles,
                  userSelect: "none",
                  color: "rgb(27 37 89)",
                  letterSpacing: "0.3px",
                }),
              }}
            />
          </div>
          <div className="mt-3 flex justify-between p-3">
            <Button
              onClick={() => {
                close(false);
              }}
              className={"bg-red-500"}
            >
              Close
            </Button>
            <Button
              onClick={() => {
                Save();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewAdmin;
