import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAgentStore from "store/useAgent";
import Table from "./table";

const UsersM = () => {
  const [data, setData] = useState([]);
  console.log("🚀 ~ file: UsersM.jsx:9 ~ UsersM ~ data:", data);
  const { Agent } = useAgentStore();

  const getAllUsers = async () => {
    try {
      const ServerRes = await axios.get("/api/user/getAllByUser");
      setData(ServerRes.data);
    } catch (error) {
      console.log("🚀 ~ file: UsersM.jsx:10 ~ getAllUsers ~ error:", error);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  if (!Agent.label === "agent") {
    return "this path is not for you please go back to the main page and if you want to continue contact with us";
  } else {
    return (
      <div>
        <div className="flex items-center justify-between">
          <div>total user: {data.length}</div>
          <Link
            to="/agent/users/add-new"
            className="rounded-md bg-blueSecondary px-4 py-2 font-bold text-white shadow-md transition-all duration-200 hover:bg-blue-600"
          >
            Add New User
          </Link>
        </div>
        <div className="relative mt-3 w-full">
          {/* table  */}

          <Table
            column={[
              {
                Header: "ID",
                accessor: "id",
              },
              {
                Header: "Identity",
                accessor: "name",
                Cell: (prop) => {
                  return (
                    <div>
                      <h1 className="text-xl">{prop.row.original.name}</h1>
                      <p>{prop.row.original.email}</p>
                      <p>{prop.row.original.phone}</p>
                    </div>
                  );
                },
              },
              {
                Header: "Nid",
                accessor: "nid_no",
              },
              // {
              //   Header: "Photo",
              //   accessor: "photo",
              // },
              {
                Header: "Date",
                accessor: "createdAt",
                Cell: (prop) => {
                  return (
                    <div>
                      <p>
                        <span>Request: </span>
                        {new Date(prop.row.original.createdAt).toDateString()}
                      </p>
                      <p>
                        <span>Last Update: </span>
                        {new Date(prop.row.original.updatedAt).toDateString()}
                      </p>
                    </div>
                  );
                },
              },
              {
                Header: "Account",
                accessor: "balance",
                Cell: (prop) => {
                  return (
                    <div>
                      <p>
                        <span>balance:</span>
                        {prop.row.original.balance}
                      </p>
                      <p>
                        <span>Rate:</span>
                        {prop.row.original.rate}
                      </p>
                    </div>
                  );
                },
              },
              {
                Header: "Action",
                accessor: "email",
                Cell: (prop) => {
                  return (
                    <>
                      {+prop.row.original.status ? (
                        +prop.row.original.status !== 400 ? (
                          <div className="flex justify-center">
                            <Link
                              to={`/agent/users/details/${prop.row.original.id}`}
                              className="flex items-center rounded bg-brand-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                            >
                              <span className="text-xl">{/* <PhAddressBookFill /> */}</span> details
                            </Link>
                          </div>
                        ) : (
                          <button className="ml-3 rounded bg-red-500 px-4 py-2 font-bold text-white ">Rejected</button>
                        )
                      ) : (
                        <div className="flex justify-center">
                          <button
                            onClick={() => {
                              // approve(prop.row.original.id);
                            }}
                            className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() => {
                              // reject(prop.row.original.id);
                            }}
                            className="ml-3 rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </>
                  );
                },
              },
            ]}
            datas={data}
          />
        </div>
      </div>
    );
  }
};

export default UsersM;
