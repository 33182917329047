import axios from "axios";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";

function numberToWords(number) {
  const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

  function convertBelowThousand(num) {
    if (num === 0) {
      return "";
    } else if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 11];
    } else if (num < 100) {
      return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + units[num % 10] : "");
    } else {
      return units[Math.floor(num / 100)] + " Hundred" + (num % 100 !== 0 ? " " + convertBelowThousand(num % 100) : "");
    }
  }

  function addScaleWord(num, scaleWord) {
    return convertBelowThousand(num) + " " + scaleWord + (num !== 0 ? " " : "");
  }

  if (number === 0) {
    return "Zero";
  } else {
    let result = "";
    let billionPart = Math.floor(number / 1000000000);
    let millionPart = Math.floor((number % 1000000000) / 1000000);
    let thousandPart = Math.floor((number % 1000000) / 1000);
    let remainingPart = number % 1000;

    if (billionPart > 0) {
      result += addScaleWord(billionPart, "Billion");
    }

    if (millionPart > 0) {
      result += addScaleWord(millionPart, "Million");
    }

    if (thousandPart > 0) {
      result += addScaleWord(thousandPart, "Thousand");
    }

    result += convertBelowThousand(remainingPart);

    return result.trim();
  }
}

const PaperToPrint = ({ data, rf, date, Rate, Agent }) => {
  console.log("🚀 ~ PaperToPrint ~ Agent:", Agent);

  const [boss, SetBoss] = useState();

  const TotalDU = data.filter((e) => e.process === "DU").length;

  useEffect(() => {
    if (Agent?.boss) {
      const getData = async () => {
        try {
          const ResToBoss = await toast.promise(
            axios.post("/api/user/get-all-user-of-agent", {
              agentID: Agent.boss,
            }),
            {
              pending: "Reporting User Is Loading ....",
              success: "Reporting User Is Loading ....",
              error: "something went wrong !!",
            }
          );
          SetBoss(ResToBoss.data[0]);
        } catch (error) {
          console.log("🚀 ~ getData ~ error:", error);
        }
      };
      getData();
    }
  }, [Agent]);
  return (
    <div ref={rf} className="relative  w-[900px]  p-2 py-20">
      <div className="relative grid w-full grid-cols-5 pb-4">
        {/* agent information  */}
        {Agent ? (
          <div className="col-span-2 w-full">
            <h1 className="text-3xl font-semibold capitalize">{Agent.name}</h1>
            <a href={`mailto:${Agent.email}`}>Email: {Agent.email}</a>
            <br />
            <a href={`tel:+88${Agent.phone}`}>Phone: {Agent.phone}</a>
            <br />
            <address className="text-sm font-light">{Agent.address}</address>
            {boss && (
              <div>
                <hr />
                <h1>Reporting Agent:</h1>
                <p className="capitalize">Name: {boss.name}</p>
                <a href={`tel:+88${boss.phone}`}>Phone: {boss.phone}</a>
                <br />
                <a href={`mailto:${boss.email}`}>Email: {boss.email}</a>
                <br />
                <address className="text-sm font-light">{boss.address}</address>
              </div>
            )}
          </div>
        ) : (
          <div>No Agent Found</div>
        )}

        {/* logo  */}
        <div className="flex w-full items-center justify-center">
          <img src="/logoastha.png" alt="astha trip" className="h-32 w-32" />
        </div>
        <div className="absolute top-28 flex h-72 w-full items-center justify-center">
          <img src="/logoastha.png" alt="astha trip" className="relative  h-auto w-60 opacity-5" />
        </div>
        {/* date */}
        <div className="relative col-span-2 flex w-full flex-col items-end justify-end text-right">
          <QRCode color="black" size={60} value="https://singapore.asthatrip.com" />
          <h1 className="text-lg font-bold">This Invoice Date</h1>
          {date?.startDate ? (
            <div>
              <span>From: </span>
              <span>{new Date(date?.startDate).toDateString() || "Life Time"}</span>
              <br />
              <span>To: </span>
              <span>{new Date(date?.endDate).toDateString() || "Life Time"}</span>
            </div>
          ) : (
            <div>All Data</div>
          )}
        </div>
      </div>
      <div>
        <table className="min-w-full">
          <thead className="border-b bg-gray-200">
            <tr>
              <th scope="col" className="px-6 py-2 text-left text-sm font-medium text-gray-900">
                Date
              </th>
              <th scope="col" className="px-6 py-2 text-left text-sm font-medium text-gray-900">
                Passport Number
              </th>
              <th scope="col" className="px-6 py-2 text-left text-sm font-medium text-gray-900">
                Name
              </th>
              <th scope="col" className="px-6 py-2 text-left text-sm font-medium text-gray-900">
                Country
              </th>
              <th scope="col" className="px-6 py-2 text-left text-sm font-medium text-gray-900">
                Payment Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              .sort((a, b) => new Date(a.approve_date) - new Date(b.approve_date))
              .map((e) => {
                return (
                  <tr className="border-b bg-white transition duration-300 ease-in-out hover:bg-gray-100">
                    <td className="whitespace-nowrap px-6 py-2 text-sm font-medium text-gray-900">
                      {new Date(e.approve_date).toDateString()}
                    </td>
                    <td className="whitespace-nowrap px-6 py-2 text-sm font-light text-gray-900">{e.pasport_number}</td>
                    <td className="whitespace-nowrap px-6 py-2 text-sm font-light text-gray-900">{e.guest_name}</td>
                    <td className="whitespace-nowrap px-6 py-2 text-sm font-light text-gray-900">{e.country}</td>
                    <td className="whitespace-nowrap px-6 py-2 text-sm font-light text-gray-900">{e.process}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="w-full p-3 ">
        <p>
          <span className="font-bold">Total Due LOI:</span> {TotalDU}
        </p>
        <p>
          <span className="font-bold">Unit Price / BDT:</span> {Rate}
        </p>
        <p>
          <span className="font-bold">Total Charge In / BDT:</span> {TotalDU * Rate}
        </p>
        <p>
          <span className="font-bold">In Word:</span> {numberToWords(TotalDU * Rate)} BDT
        </p>
      </div>
      <div className="w-full py-4 text-center italic text-red-600">
        I THERE FORE PRAY & HOPE THAT YOU WOULD BE KIND ENOUGH TO PAY THE BILL & OBLIGE THERE BY.{" "}
      </div>
    </div>
  );
};

export default PaperToPrint;
