// Admin Imports
import Accounts from "views/admin/Accounts/index";
import AdminList from "views/admin/Admin/index";
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import BalanceReq from "./views/admin/BalanceReq/Index";
import Form from "./views/admin/From/Index";
import Agent from "./views/admin/agent/index";
import EmailSetup from "./views/admin/email/EmailSetup";
import Payment from "./views/admin/payment/Index";
import User from "./views/admin/user/index";

// // Auth Imports
// import SignIn from "views/auth/SignIn";

// Icon Imports
import { MdHome, MdPerson } from "react-icons/md";
import Report from "views/admin/Report/Index";

const routes = [
  {
    name: "Dashboard",
    role: "dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Agent",
    role: "agent",
    layout: "/admin",
    path: "agent",
    icon: <MaterialSymbolsPersonRaisedHand className="h-6 w-6" />,
    component: <Agent />,
    secondary: true,
  },
  {
    name: "User",
    role: "user",
    layout: "/admin",
    path: "user",
    icon: <MdiAccountGroupOutline className="h-6 w-6" />,
    component: <User />,
    secondary: true,
  },
  {
    name: "Balance request",
    role: "balance-request",
    layout: "/admin",
    icon: <TableMoneybag className="h-6 w-6" />,
    path: "balancerequest",
    component: <BalanceReq />,
  },
  {
    name: "Form Request",
    role: "from-request",
    layout: "/admin",
    icon: <LineMdClipboardList className="h-6 w-6" />,
    path: "form",
    component: <Form />,
  },
  {
    name: "Payment Sheet",
    role: "payment-sheet",
    layout: "/admin",
    icon: <SolarWalletMoneyOutline className="h-6 w-6" />,
    path: "payment",
    component: <Payment />,
  },
  {
    name: "Email Setup",
    role: "email-setup",
    layout: "/admin",
    path: "email-setup",
    icon: <MailCog className="h-6 w-6" />,
    component: <EmailSetup />,
  },
  {
    name: "Report",
    role: "report",
    layout: "/admin",
    path: "report",
    icon: <CarbonReportData className="h-6 w-6" />,
    component: <Report />,
  },
  // {
  //   name: "Accounts",
  //   role: "accounts",
  //   layout: "/admin",
  //   path: "accounts",
  //   icon: <AccountsIcon className="h-6 w-6" />,
  //   component: <Accounts />,
  // },
  {
    name: "Attendance",
    role: "attendance",
    layout: "/admin",
    path: "attendance",
    icon: <Add className="h-6 w-6" />,
    component: <Report />,
  },
  {
    name: "Setting",
    role: "settings",
    layout: "/admin",
    path: "setting",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Admin",
    role: "admin",
    layout: "/admin",
    path: "profile",
    icon: <AdminIcon className="h-6 w-6" />,
    component: <AdminList />,
  },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;

function AdminIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="m17 21.25l-4.5-2.625V13.4l4.5-2.625l4.5 2.625v5.2zm-2.35-7.4L17 15.225l2.35-1.375L17 12.5zm3.1 5.2l2.25-1.3V15l-2.25 1.325zM14 17.75l2.25 1.325V16.35L14 15.025zM10 12q-1.65 0-2.825-1.175T6 8q0-1.65 1.175-2.825T10 4q1.65 0 2.825 1.175T14 8q0 1.65-1.175 2.825T10 12m-8 8v-2.8q0-.825.425-1.55t1.175-1.1q1.275-.65 2.875-1.1T10 13h.35q.15 0 .3.05q-.2.45-.337.938T10.1 15H10q-1.775 0-3.187.45t-2.313.9q-.225.125-.363.35T4 17.2v.8h6.3q.15.525.4 1.038t.55.962zm8-10q.825 0 1.413-.587T12 8q0-.825-.587-1.412T10 6q-.825 0-1.412.588T8 8q0 .825.588 1.413T10 10m.3 8"
      ></path>
    </svg>
  );
}

function AccountsIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M5 16v-5q0-.425.288-.712T6 10q.425 0 .713.288T7 11v5q0 .425-.288.713T6 17q-.425 0-.712-.288T5 16m6 0v-5q0-.425.288-.712T12 10q.425 0 .713.288T13 11v5q0 .425-.288.713T12 17q-.425 0-.712-.288T11 16m-8 5q-.425 0-.712-.288T2 20q0-.425.288-.712T3 19h18q.425 0 .713.288T22 20q0 .425-.288.713T21 21zm14-5v-5q0-.425.288-.712T18 10q.425 0 .713.288T19 11v5q0 .425-.288.713T18 17q-.425 0-.712-.288T17 16m4-8H2.9q-.375 0-.638-.262T2 7.1v-.55q0-.275.138-.475T2.5 5.75l8.6-4.3q.425-.2.9-.2t.9.2l8.55 4.275q.275.125.413.375t.137.525V7q0 .425-.287.713T21 8M6.45 6h11.1zm0 0h11.1L12 3.25z"
      ></path>
    </svg>
  );
}
function Add(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M21 21V5H3v7q0 .425-.288.713T2 13q-.425 0-.712-.288T1 12V5q0-.825.588-1.412T3 3h18q.825 0 1.413.588T23 5v14q0 .825-.587 1.413T21 21M9 14q-1.65 0-2.825-1.175T5 10q0-1.65 1.175-2.825T9 6q1.65 0 2.825 1.175T13 10q0 1.65-1.175 2.825T9 14m0-2q.825 0 1.413-.587T11 10q0-.825-.587-1.412T9 8q-.825 0-1.412.588T7 10q0 .825.588 1.413T9 12M3 22q-.825 0-1.412-.587T1 20v-.8q0-.85.438-1.562T2.6 16.55q1.55-.775 3.15-1.162T9 15q1.65 0 3.25.388t3.15 1.162q.725.375 1.163 1.088T17 19.2v.8q0 .825-.587 1.413T15 22zm0-2h12v-.8q0-.275-.137-.5t-.363-.35q-1.35-.675-2.725-1.012T9 17q-1.4 0-2.775.338T3.5 18.35q-.225.125-.363.35T3 19.2zm6 0"
      ></path>
    </svg>
  );
}

export function MaterialSymbolsPersonRaisedHand(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M2 23v-2h20v2H2Zm2-3v-6q-.825-1.35-1.275-2.863t-.45-3.087q0-1.525.388-3t.912-2.9q.2-.525.65-.838t1-.312Q6 1 6.55 1.525T7 2.775L6.725 5.05q-.15 1.2.213 2.275t1.087 1.887q.725.813 1.75 1.3T12 11q1.5 0 3.013.313t2.637.887q1.125.575 1.738 1.463T20 15.85V20H10v-.925q0-.85.575-1.463T12 17h4v-2h-4q-1.675 0-2.838 1.2T8 19.075V20H4Zm8-10q-1.65 0-2.825-1.175T8 6q0-1.65 1.175-2.825T12 2q1.65 0 2.825 1.175T16 6q0 1.65-1.175 2.825T12 10Z"
      ></path>
    </svg>
  );
}

export function MailCog(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M12 19H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v5"></path>
        <path d="m3 7l9 6l9-6m-3.999 12a2 2 0 1 0 4 0a2 2 0 1 0-4 0m2-3.5V17m0 4v1.5m3.031-5.25l-1.299.75m-3.463 2l-1.3.75m0-3.5l1.3.75m3.463 2l1.3.75"></path>
      </g>
    </svg>
  );
}

export function TableMoneybag(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <path d="M9.5 3h5A1.5 1.5 0 0 1 16 4.5A3.5 3.5 0 0 1 12.5 8h-1A3.5 3.5 0 0 1 8 4.5A1.5 1.5 0 0 1 9.5 3z"></path>
        <path d="M4 17v-1a8 8 0 1 1 16 0v1a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4z"></path>
      </g>
    </svg>
  );
}

export function SolarWalletMoneyOutline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <defs>
        <path id="solarWalletMoneyOutline0" d="M19 14a1 1 0 1 1-2 0a1 1 0 0 1 2 0Z"></path>
      </defs>
      <g fill="currentColor">
        <path
          fillRule="evenodd"
          d="M20.924 11.75H18.23c-1.424 0-2.481 1.059-2.481 2.25s1.057 2.25 2.48 2.25h2.718c.206-.013.295-.152.302-.236v-4.028c-.007-.084-.096-.223-.302-.235h-.024Zm-.074-1.5c.066 0 .13 0 .19.004c.87.053 1.641.71 1.706 1.628c.004.06.004.125.004.185v3.866c0 .06 0 .125-.004.185c-.065.918-.836 1.575-1.707 1.629c-.059.003-.123.003-.19.003h-2.618c-2.145 0-3.981-1.628-3.981-3.75s1.836-3.75 3.98-3.75h2.62Z"
          clipRule="evenodd"
        ></path>
        <use href="#solarWalletMoneyOutline0"></use>
        <path
          fillRule="evenodd"
          d="M20.85 10.25a1.888 1.888 0 0 1 .835.16c-.107-1.606-.402-2.844-1.326-3.769c-.749-.748-1.698-1.08-2.87-1.238l-.042-.005a.778.778 0 0 0-.032-.023l-3.736-2.477a3.987 3.987 0 0 0-4.358 0L5.586 5.375a.773.773 0 0 0-.033.023l-.042.005c-1.172.158-2.121.49-2.87 1.238c-.748.749-1.08 1.698-1.238 2.87c-.153 1.14-.153 2.595-.153 4.433v.112c0 1.838 0 3.294.153 4.433c.158 1.172.49 2.121 1.238 2.87c.749.748 1.698 1.08 2.87 1.238c1.14.153 2.595.153 4.433.153h3.112c1.838 0 3.294 0 4.433-.153c1.172-.158 2.121-.49 2.87-1.238c.924-.925 1.219-2.163 1.326-3.77c-.202.09-.42.144-.646.158c-.059.003-.123.003-.19.003h-.681c-.114 1.341-.371 2.05-.87 2.548c-.423.423-1.003.677-2.009.812c-1.027.138-2.382.14-4.289.14h-3c-1.907 0-3.261-.002-4.29-.14c-1.005-.135-1.585-.389-2.008-.812c-.423-.423-.677-1.003-.812-2.009c-.138-1.027-.14-2.382-.14-4.289c0-1.907.002-3.261.14-4.29c.135-1.005.389-1.585.812-2.008c.423-.423 1.003-.677 2.009-.812c1.028-.138 2.382-.14 4.289-.14h3c1.907 0 3.262.002 4.29.14c1.005.135 1.585.389 2.008.812c.499.498.756 1.207.87 2.548h.682Zm-10.906-5h3.112c.517 0 1.003 0 1.46.003L12.85 4.148c-.8-.53-1.9-.53-2.7 0L8.483 5.253c.458-.003.944-.003 1.46-.003Z"
          clipRule="evenodd"
        ></path>
        <path d="M6 9.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5H6Z"></path>
        <use href="#solarWalletMoneyOutline0" fillRule="evenodd" clipRule="evenodd"></use>
      </g>
    </svg>
  );
}

export function LineMdClipboardList(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
        <g strokeWidth="2">
          <path strokeDasharray="66" strokeDashoffset="66" d="M12 3H19V21H5V3H12Z">
            <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.6s" values="66;0"></animate>
          </path>
          <path strokeDasharray="5" strokeDashoffset="5" d="M9 10H12">
            <animate fill="freeze" attributeName="stroke-dashoffset" begin="1s" dur="0.2s" values="5;0"></animate>
          </path>
          <path strokeDasharray="6" strokeDashoffset="6" d="M9 13H14">
            <animate fill="freeze" attributeName="stroke-dashoffset" begin="1.2s" dur="0.2s" values="6;0"></animate>
          </path>
          <path strokeDasharray="7" strokeDashoffset="7" d="M9 16H15">
            <animate fill="freeze" attributeName="stroke-dashoffset" begin="1.4s" dur="0.2s" values="7;0"></animate>
          </path>
        </g>
        <path strokeDasharray="12" strokeDashoffset="12" d="M14.5 3.5V6.5H9.5V3.5">
          <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.7s" dur="0.2s" values="12;0"></animate>
        </path>
      </g>
    </svg>
  );
}

export function MdiAccountGroupOutline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12 5a3.5 3.5 0 0 0-3.5 3.5A3.5 3.5 0 0 0 12 12a3.5 3.5 0 0 0 3.5-3.5A3.5 3.5 0 0 0 12 5m0 2a1.5 1.5 0 0 1 1.5 1.5A1.5 1.5 0 0 1 12 10a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 12 7M5.5 8A2.5 2.5 0 0 0 3 10.5c0 .94.53 1.75 1.29 2.18c.36.2.77.32 1.21.32c.44 0 .85-.12 1.21-.32c.37-.21.68-.51.91-.87A5.42 5.42 0 0 1 6.5 8.5v-.28c-.3-.14-.64-.22-1-.22m13 0c-.36 0-.7.08-1 .22v.28c0 1.2-.39 2.36-1.12 3.31c.12.19.25.34.4.49a2.482 2.482 0 0 0 1.72.7c.44 0 .85-.12 1.21-.32c.76-.43 1.29-1.24 1.29-2.18A2.5 2.5 0 0 0 18.5 8M12 14c-2.34 0-7 1.17-7 3.5V19h14v-1.5c0-2.33-4.66-3.5-7-3.5m-7.29.55C2.78 14.78 0 15.76 0 17.5V19h3v-1.93c0-1.01.69-1.85 1.71-2.52m14.58 0c1.02.67 1.71 1.51 1.71 2.52V19h3v-1.5c0-1.74-2.78-2.72-4.71-2.95M12 16c1.53 0 3.24.5 4.23 1H7.77c.99-.5 2.7-1 4.23-1Z"
      ></path>
    </svg>
  );
}

export function CarbonReportData(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
      <path fill="currentColor" d="M15 20h2v4h-2zm5-2h2v6h-2zm-10-4h2v10h-2z"></path>
      <path
        fill="currentColor"
        d="M25 5h-3V4a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v1H7a2 2 0 0 0-2 2v21a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2M12 4h8v4h-8Zm13 24H7V7h3v3h12V7h3Z"
      ></path>
    </svg>
  );
}
