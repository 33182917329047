import axios from "axios";
import Checkbox from "components/checkbox";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import PaperToPrint from "./PaperToPrint";

//

const ModelReport = ({ data, close, date, email }) => {
  const [DU, SetDU] = useState(true);
  const [PD, SetPD] = useState(false);
  const [CL, SetCL] = useState(false);
  const [Rate, SetRate] = useState(0);
  const [Agent, SetAgent] = useState(null);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const FilterData = data.filter((e) => {
    return (e.process === "DU" && DU) || (e.process === "CL" && CL) || (e.process === "PD" && PD);
  });

  const GetData = async () => {
    try {
      const data = await axios.get(`/api/agent/get/${email}`);
      SetAgent(data.data);
    } catch (error) {
      console.log("🚀 ~ GetData ~ error:", error);
    }
  };

  useEffect(() => {
    GetData();
  }, []);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        // toast("check");
        close();
      }}
      className="fixed left-0 top-0 z-50 h-full w-full bg-lightPrimary/20 backdrop-blur-sm"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="mx-auto mt-10 h-[600px] w-full max-w-[800px] overflow-auto rounded-md bg-lightPrimary p-5 shadow-md"
      >
        <div className="border-b-2 p-2 text-center">
          <h1 className="w-full text-xl font-bold">Make A Report Invoice</h1>
        </div>
        <div className="flex w-full gap-8 p-3">
          <span>Filter The LOI:</span>
          <div className="flex items-center gap-3">
            <Checkbox
              onChange={(e) => {
                SetDU(e.target.checked);
              }}
              checked={DU}
              color="red"
            />
            <span>DU</span>
          </div>
          <div className="flex items-center gap-3">
            <Checkbox
              onChange={(e) => {
                SetPD(e.target.checked);
              }}
              checked={PD}
              color="green"
            />
            <span>PD</span>
          </div>
          <div className="flex items-center gap-3">
            <Checkbox
              onChange={(e) => {
                SetCL(e.target.checked);
              }}
              checked={CL}
              color="yellow"
            />
            <span>CL</span>
          </div>
          <div>
            <label htmlFor="rate">LOI Rate:</label>
            <input
              onChange={(e) => {
                SetRate(e.target.value);
              }}
              value={Rate}
              type="number"
              className="ml-3 rounded-md p-2 outline-none ring-blueSecondary focus:ring-2"
            />
          </div>
        </div>
        <button className="rounded-sm bg-blueSecondary px-5 py-3 text-white" onClick={handlePrint}>
          Print this out!
        </button>

        <div className="mt-3 overflow-x-auto">
          <PaperToPrint rf={componentRef} data={FilterData} date={date} Rate={Rate} Agent={Agent} />
        </div>
      </div>
    </div>
  );
};

export default ModelReport;
