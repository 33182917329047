export const countryData = [
  {
    name: "Bangladesh",
    nationality: "Bangladeshi",
  },
  {
    name: "Afghanistan",
    nationality: "Afghan",
  },
  {
    name: "Aland Islands",
    nationality: "Aland Islands",
  },
  {
    name: "Albania",
    nationality: "Albanian",
  },
  {
    name: "Algeria",
    nationality: "Algerian",
  },
  {
    name: "American Samoa",
    nationality: "American Saoma",
  },
  {
    name: "Andorra",
    nationality: "Andorran",
  },
  {
    name: "Angola",
    nationality: "Angolan",
  },
  {
    name: "Anguilla",
    nationality: "Anguillan",
  },
  {
    name: "Antarctica",
    nationality: "Antarctican",
  },
  {
    name: "Antigua And Barbuda",
    nationality: "Antiguans",
  },
  {
    name: "Argentina",
    nationality: "Argentinean",
  },
  {
    name: "Armenia",
    nationality: "Armenian",
  },
  {
    name: "Aruba",
    nationality: "Aruba",
  },
  {
    name: "Australia",
    nationality: "Australian",
  },
  {
    name: "Austria",
    nationality: "Austrian",
  },
  {
    name: "Azerbaijan",
    nationality: "Azerbaijani",
  },
  {
    name: "Bahamas",
    nationality: "Bahamian",
  },
  {
    name: "Bahrain",
    nationality: "Bahraini",
  },
  {
    name: "Barbados",
    nationality: "Barbudans",
  },
  {
    name: "Belarus",
    nationality: "Belarusian",
  },
  {
    name: "Belgium",
    nationality: "Belgian",
  },
  {
    name: "Belize",
    nationality: "Belizean",
  },
  {
    name: "Benin",
    nationality: "Beninese",
  },
  {
    name: "Bermuda",
    nationality: "Bermudan",
  },
  {
    name: "Bhutan",
    nationality: "Bhutanese",
  },
  {
    name: "Bolivia",
    nationality: "Bolivian",
  },
  {
    name: "Bosnia And Herzegovina",
    nationality: "Bosnian",
  },
  {
    name: "Botswana",
    nationality: "Botswananian",
  },
  {
    name: "Bouvet Island",
    nationality: "Bouvet Island",
  },
  {
    name: "Brazil",
    nationality: "Brazilian",
  },
  {
    name: "British Indian Ocean Territory",
    nationality: "British Indian Ocean Territory",
  },
  {
    name: "Brunei Darussalam",
    nationality: "bruneian",
  },
  {
    name: "Bulgaria",
    nationality: "Bulgarian",
  },
  {
    name: "Burkina Faso",
    nationality: "Burkinabe",
  },
  {
    name: "Burundi",
    nationality: "Burundian",
  },
  {
    name: "Cambodia",
    nationality: "Cambodian",
  },
  {
    name: "Cameroon",
    nationality: "Cameroonian",
  },
  {
    name: "Canada",
    nationality: "Canadian",
  },
  {
    name: "Cape Verde",
    nationality: "Cape Verdean",
  },
  {
    name: "Cayman Islands",
    nationality: "Cayman Islands",
  },
  {
    name: "Central African Republic",
    nationality: "Central African",
  },
  {
    name: "Chad",
    nationality: "Chadian",
  },
  {
    name: "Chile",
    nationality: "Chilean",
  },
  {
    name: "China",
    nationality: "Chinese",
  },
  {
    name: "Christmas Island",
    nationality: "Christmas Island",
  },
  {
    name: "Cocos (Keeling) Islands",
    nationality: "Cocos (Keeling) Islands",
  },
  {
    name: "Colombia",
    nationality: "Colombian",
  },
  {
    name: "Comoros",
    nationality: "Comoran",
  },
  {
    name: "Congo",
    nationality: "Congolese",
  },
  {
    name: "Congo, Democratic Republic",
    nationality: "Congo, Democratic Republic",
  },
  {
    name: "Cook Islands",
    nationality: "Cook Islands",
  },
  {
    name: "Costa Rica",
    nationality: "Costa Rican",
  },
  {
    name: "Cote D'Ivoire",
    nationality: "Cote D'Ivoires",
  },
  {
    name: "Croatia",
    nationality: "Croatian",
  },
  {
    name: "Cuba",
    nationality: "Cuban",
  },
  {
    name: "Cyprus",
    nationality: "Cypriot",
  },
  {
    name: "Czech Republic",
    nationality: "Czech",
  },
  {
    name: "Denmark",
    nationality: "Danish",
  },
  {
    name: "Djibouti",
    nationality: "Djibouti",
  },
  {
    name: "Dominica",
    nationality: "Dominican",
  },
  {
    name: "Dominican Republic",
    nationality: "Dominican Republic",
  },
  {
    name: "Ecuador",
    nationality: "Ecuadorean",
  },
  {
    name: "Egypt",
    nationality: "Egyptian",
  },
  {
    name: "El Salvador",
    nationality: "El Salvadorian",
  },
  {
    name: "Equatorial Guinea",
    nationality: "Equatorial Guinean",
  },
  {
    name: "Eritrea",
    nationality: "Eritrean",
  },
  {
    name: "Estonia",
    nationality: "Estonian",
  },
  {
    name: "Ethiopia",
    nationality: "Ethiopian",
  },
  {
    name: "Falkland Islands (Malvinas)",
    nationality: "Falkland Islands (Malvinas)",
  },
  {
    name: "Faroe Islands",
    nationality: "Faroe Islands",
  },
  {
    name: "Fiji",
    nationality: "Fijian",
  },
  {
    name: "Finland",
    nationality: "Finnish",
  },
  {
    name: "France",
    nationality: "French",
  },
  {
    name: "French Guiana",
    nationality: "French Guiana",
  },
  {
    name: "French Polynesia",
    nationality: "French Polynesia",
  },
  {
    name: "French Southern Territories",
    nationality: "French Southern Territories",
  },
  {
    name: "Gabon",
    nationality: "Gabonese",
  },
  {
    name: "Gambia",
    nationality: "Gambian",
  },
  {
    name: "Georgia",
    nationality: "Georgian",
  },
  {
    name: "Germany",
    nationality: "German",
  },
  {
    name: "Ghana",
    nationality: "Ghanaian",
  },
  {
    name: "Gibraltar",
    nationality: "Gibraltarian",
  },
  {
    name: "Greece",
    nationality: "Greek",
  },
  {
    name: "Greenland",
    nationality: "Greenlandian",
  },
  {
    name: "Grenada",
    nationality: "Grenadian",
  },
  {
    name: "Guadeloupe",
    nationality: "Guadeloupe",
  },
  {
    name: "Guam",
    nationality: "Guamian",
  },
  {
    name: "Guatemala",
    nationality: "Guatemalan",
  },
  {
    name: "Guernsey",
    nationality: "Guernses",
  },
  {
    name: "Guinea",
    nationality: "Guinean",
  },
  {
    name: "Guinea-Bissau",
    nationality: "Bissau-Guinean",
  },
  {
    name: "Guyana",
    nationality: "Guyanese",
  },
  {
    name: "Haiti",
    nationality: "Haitian",
  },
  {
    name: "Heard Island & Mcdonald Islands",
    nationality: "Heard Island & Mcdonald Islands",
  },
  {
    name: "Holy See (Vatican City State)",
    nationality: "Holy See (Vatican City State)",
  },
  {
    name: "Honduras",
    nationality: "Honduran",
  },
  {
    name: "Hong Kong",
    nationality: "Hong Kong (Chinese)",
  },
  {
    name: "Hungary",
    nationality: "Hungarian",
  },
  {
    name: "Iceland",
    nationality: "Icelander",
  },
  {
    name: "India",
    nationality: "Indian",
  },
  {
    name: "Indonesia",
    nationality: "Indonesian",
  },
  {
    name: "Iran, Islamic Republic Of",
    nationality: "Iranian",
  },
  {
    name: "Iraq",
    nationality: "Iraqi",
  },
  {
    name: "Ireland",
    nationality: "Irish",
  },
  {
    name: "Isle Of Man",
    nationality: "Isle Of Man",
  },
  {
    name: "Israel",
    nationality: "Israeli",
  },
  {
    name: "Italy",
    nationality: "Italian",
  },
  {
    name: "Jamaica",
    nationality: "Jamaican",
  },
  {
    name: "Japan",
    nationality: "Japanese",
  },
  {
    name: "Jersey",
    nationality: "Jersey",
  },
  {
    name: "Jordan",
    nationality: "Jordanian",
  },
  {
    name: "Kazakhstan",
    nationality: "Kazakhstani",
  },
  {
    name: "Kenya",
    nationality: "Kenyan",
  },
  {
    name: "Kiribati",
    nationality: "Kiribatin",
  },
  {
    name: "Korea South",
    nationality: "Korean (South)",
  },
  {
    name: "Korea North",
    nationality: "Korean (North)",
  },
  {
    name: "Kuwait",
    nationality: "Kuwaiti",
  },
  {
    name: "Kyrgyzstan",
    nationality: "Kyrgyz",
  },
  {
    name: "Lao People's Democratic Republic",
    nationality: "Lao People's Democratic Republic",
  },
  {
    name: "Latvia",
    nationality: "Latvian",
  },
  {
    name: "Lebanon",
    nationality: "Lebanese",
  },
  {
    name: "Lesotho",
    nationality: "Lesothon",
  },
  {
    name: "Liberia",
    nationality: "Liberian",
  },
  {
    name: "Libyan Arab Jamahiriya",
    nationality: "Libyan",
  },
  {
    name: "Liechtenstein",
    nationality: "Liechtensteiner",
  },
  {
    name: "Lithuania",
    nationality: "Lithuanian",
  },
  {
    name: "Luxembourg",
    nationality: "Luxembourger",
  },
  {
    name: "Macao",
    nationality: "Macedonianv",
  },
  {
    name: "Macedonia",
    nationality: "Macedonian",
  },
  {
    name: "Madagascar",
    nationality: "Madagascarian",
  },
  {
    name: "Malawi",
    nationality: "Malawian",
  },
  {
    name: "Malaysia",
    nationality: "Malaysian",
  },
  {
    name: "Maldives",
    nationality: "Maldivan",
  },
  {
    name: "Mali",
    nationality: "Malian",
  },
  {
    name: "Malta",
    nationality: "Maltese",
  },
  {
    name: "Marshall Islands",
    nationality: "Marshallese",
  },
  {
    name: "Martinique",
    nationality: "Mauritanian",
  },
  {
    name: "Mauritania",
    nationality: "Mauritian",
  },
  {
    name: "Mauritius",
    nationality: "Mauritiusian",
  },
  {
    name: "Mayotte",
    nationality: "Mayottes",
  },
  {
    name: "Mexico",
    nationality: "Mexican",
  },
  {
    name: "Micronesia, Federated States Of",
    nationality: "Micronesia, Federated States Of",
  },
  {
    name: "Moldova",
    nationality: "Moldovan",
  },
  {
    name: "Monaco",
    nationality: "Monacan",
  },
  {
    name: "Mongolia",
    nationality: "Mongolian",
  },
  {
    name: "Montenegro",
    nationality: "Montenegrin",
  },
  {
    name: "Montserrat",
    nationality: "Montserratan",
  },
  {
    name: "Morocco",
    nationality: "Moroccan",
  },
  {
    name: "Mozambique",
    nationality: "Mozambican",
  },
  {
    name: "Myanmar",
    nationality: "Myanmarian",
  },
  {
    name: "Namibia",
    nationality: "Namibian",
  },
  {
    name: "Nauru",
    nationality: "Nauruan",
  },
  {
    name: "Nepal",
    nationality: "Nepalese",
  },
  {
    name: "Netherlands",
    nationality: "Dutch",
  },
  {
    name: "Netherlands Antilles",
    nationality: "Netherlands Antilles",
  },
  {
    name: "New Caledonia",
    nationality: "Caledonian",
  },
  {
    name: "New Zealand",
    nationality: 'New Zealander"',
  },
  {
    name: "Nicaragua",
    nationality: "Nicaraguan",
  },
  {
    name: "Niger",
    nationality: "Nigerien",
  },
  {
    name: "Nigeria",
    nationality: "Nigerian",
  },
  {
    name: "Niue",
    nationality: "Niue",
  },
  {
    name: "Norfolk Island",
    nationality: "Norfolk Island(Australian)",
  },
  {
    name: "Northern Mariana Islands",
    nationality: "Northern Mariana Islands",
  },
  {
    name: "Norway",
    nationality: "Norwegian",
  },
  {
    name: "Oman",
    nationality: "Omani",
  },
  {
    name: "Pakistan",
    nationality: "Pakistani",
  },
  {
    name: "Palau",
    nationality: "Palauan",
  },
  {
    name: "Palestinian Territory, Occupied",
    nationality: "Palestinian",
  },
  {
    name: "Panama",
    nationality: "Panamanian",
  },
  {
    name: "Papua New Guinea",
    nationality: "Papua New Guinean",
  },
  {
    name: "Paraguay",
    nationality: "Paraguayan",
  },
  {
    name: "Peru",
    nationality: "Peruvian",
  },
  {
    name: "Philippines",
    nationality: "Philippine",
  },
  {
    name: "Pitcairn",
    nationality: "Pitcairn",
  },
  {
    name: "Poland",
    nationality: "Polish",
  },
  {
    name: "Portugal",
    nationality: "Portuguese",
  },
  {
    name: "Puerto Rico",
    nationality: "Puerto Rican",
  },
  {
    name: "Qatar",
    nationality: "Qatari",
  },
  {
    name: "Reunion",
    nationality: "Reunion",
  },
  {
    name: "Romania",
    nationality: "Romanian",
  },
  {
    name: "Russian Federation",
    nationality: "Russian",
  },
  {
    name: "Rwanda",
    nationality: "Rwandan",
  },
  {
    name: "Saint Barthelemy",
    nationality: "Saint Barthelemy",
  },
  {
    name: "Saint Helena",
    nationality: "Saint Helena",
  },
  {
    name: "Saint Kitts And Nevis",
    nationality: "Saint Kitts And Nevis",
  },
  {
    name: "Saint Lucia",
    nationality: "Saint Lucian",
  },
  {
    name: "Saint Martin",
    nationality: "Saint Martin",
  },
  {
    name: "Saint Pierre And Miquelon",
    nationality: "Saint Pierre And Miquelon",
  },
  {
    name: "Saint Vincent And Grenadines",
    nationality: "Saint Vincent And Grenadines",
  },
  {
    name: "Samoa",
    nationality: "Samoan",
  },
  {
    name: "San Marino",
    nationality: "San Marinese",
  },
  {
    name: "Sao Tome And Principe",
    nationality: "Sao Tomean",
  },
  {
    name: "Saudi Arabia",
    nationality: "Saudi",
  },
  {
    name: "Senegal",
    nationality: "Senegalese",
  },
  {
    name: "Serbia",
    nationality: "Serbian",
  },
  {
    name: "Seychelles",
    nationality: "Seychellois",
  },
  {
    name: "Sierra Leone",
    nationality: "Sierra Leonean",
  },
  {
    name: "Singapore",
    nationality: "Singaporean",
  },
  {
    name: "Slovakia",
    nationality: "Slovakian",
  },
  {
    name: "Slovenia",
    nationality: "Slovenian",
  },
  {
    name: "Solomon Islands",
    nationality: "Solomon Islander",
  },
  {
    name: "Somalia",
    nationality: "Somali",
  },
  {
    name: "South Africa",
    nationality: "South African",
  },
  {
    name: "South Georgia And Sandwich Isl.",
    nationality: "South Georgia And Sandwich Isl.",
  },
  {
    name: "Spain",
    nationality: "Spanish",
  },
  {
    name: "Sri Lanka",
    nationality: "Sri Lankan",
  },
  {
    name: "Sudan",
    nationality: "Sudanese",
  },
  {
    name: "Suriname",
    nationality: "Sudanese",
  },
  {
    name: "Svalbard And Jan Mayen",
    nationality: "Svalbard And Jan Mayen",
  },
  {
    name: "Swaziland",
    nationality: "Swazi",
  },
  {
    name: "Sweden",
    nationality: "Swedish",
  },
  {
    name: "Switzerland",
    nationality: "Swiss",
  },
  {
    name: "Syrian Arab Republic",
    nationality: "Syrian",
  },
  {
    name: "Taiwan",
    nationality: "Taiwanese",
  },
  {
    name: "Tajikistan",
    nationality: "Tajik",
  },
  {
    name: "Tanzania",
    nationality: "Tanzanian",
  },
  {
    name: "Thailand",
    nationality: "Thai",
  },
  {
    name: "Timor-Leste",
    nationality: "Timor-Leste",
  },
  {
    name: "Togo",
    nationality: "Togolese",
  },
  {
    name: "Tokelau",
    nationality: "Tokelau",
  },
  {
    name: "Tonga",
    nationality: "Tongan",
  },
  {
    name: "Trinidad And Tobago",
    nationality: "Trinidadian or Tobagonian",
  },
  {
    name: "Tunisia",
    nationality: "Tunisian",
  },
  {
    name: "Turkey",
    nationality: "Turkish",
  },
  {
    name: "Turkmenistan",
    nationality: "Turkmen",
  },
  {
    name: "Turks And Caicos Islands",
    nationality: "Turks And Caicos Islands",
  },
  {
    name: "Tuvalu",
    nationality: "Tuvaluan",
  },
  {
    name: "Uganda",
    nationality: "Ugandan",
  },
  {
    name: "Ukraine",
    nationality: "Ukrainian",
  },
  {
    name: "United Arab Emirates",
    nationality: "Emirati",
  },
  {
    name: "United Kingdom",
    nationality: "British",
  },
  {
    name: "United States",
    nationality: "American",
  },
  {
    name: "United States Outlying Islands",
    nationality: "United States Outlying Islands",
  },
  {
    name: "Uruguay",
    nationality: "Uruguayan",
  },
  {
    name: "Uzbekistan",
    nationality: "Uzbekistani",
  },
  {
    name: "Vanuatu",
    nationality: "Vanuatu",
  },
  {
    name: "Venezuela",
    nationality: "Venezuelan",
  },
  {
    name: "Viet Nam",
    nationality: "Vietnamese",
  },
  {
    name: "Virgin Islands, British",
    nationality: "Virgin Islands, British",
  },
  {
    name: "Virgin Islands, U.S.",
    nationality: "Virgin Islands, U.S.",
  },
  {
    name: "Wallis And Futuna",
    nationality: "Welsh",
  },
  {
    name: "Western Sahara",
    nationality: "Western Sahara",
  },
  {
    name: "Yemen",
    nationality: "Yemenite",
  },
  {
    name: "Zambia",
    nationality: "Zambian",
  },
  {
    name: "Zimbabwe",
    nationality: "Zimbabwean",
  },
];
