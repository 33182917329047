/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";

const useReport = (router) => {
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [re, SetRe] = useState(1);

  const reload = () => {
    SetRe((e) => {
      return e + 1;
    });
  };

  const getData = async (c) => {
    try {
      const resDb = await axios.get(`/api/report/${c}`);
      setData(resDb.data);

      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ file: useReport.js:14 ~ getData ~ error:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Loading) {
      return;
    }
    setLoading(true);
    getData(router);
  }, [re, router]);

  return { data, Loading, error, reload };
};

export default useReport;
