import React from "react";

const Logo = () => {
  return (
    <div className="flex items-center justify-start">
      <img src="/logoastha.png" alt="asthatrip" className="h-12" />
      <h1 className="text-2xl font-bold text-[#ff0000]">Astha Trip</h1>
    </div>
  );
};

export default Logo;
