import { Textarea } from "@chakra-ui/react";
import axios from "axios";
import { Button } from "components/form/Button";
import { Input } from "components/form/Input";
import { Select } from "components/form/Select";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import PrintMoney from "views/agent/totalInfo/PrintMoney";
import { Exchange_rate, MoneyRecipeService } from "views/public/Entry/MainEntry";

const ChangeRecept = ({ data, setReload }) => {
  const [Status, setStatus] = useState(data?.m_status);
  const [M_ex_des, setM_ex_des] = useState(data?.m_ex_des);
  const [Service_confirm, setService_confirm] = useState(data?.service_confirm);
  const [Amount, setAmount] = useState(data?.m_amount);
  const [Show, setShow] = useState(data?.m_show);
  const [Family, setFamily] = useState(data?.family);
  const [M_payment_status, setM_payment_status] = useState(data?.m_payment_status);
  const [M_service, setM_service] = useState(data?.m_service);
  const [M_rate, setM_rate] = useState(data?.m_rate);

  // change
  const ChangeStatus = async () => {
    try {
      const resSV = await toast.promise(
        axios.post("/api/loi/change_recept_status", {
          status: Status,
          amount: Amount,
          m_show: Show,
          m_payment_status: M_payment_status,
          m_service: M_service?.value?.name,
          Family: Family,
          M_rate: M_rate,
          Service_confirm: Service_confirm,
          Exchange_rate: Exchange_rate,
          m_ex_des: M_ex_des,
          id: data.id,
        }),
        {
          pending: "Please wait...",
          success: "Status Changed",
          error: "Somethings is wrong",
        },
        {
          position: "top-center",
        }
      );
      console.log(resSV);
      // setReload((e) => e + 1);
    } catch (error) {
      console.log("🚀 ~ ChangeStatus ~ error:", error);
    }
  };

  return (
    <div className=" relative my-2 mt-5 w-full rounded-md border-2 border-red-500 p-3">
      <h1 className="pb-2 text-center font-bold">Update Receipt</h1>
      <p className="w-full whitespace-normal py-2  font-light">Selected Service: {data?.m_service} </p>
      <CreatableSelect
        options={MoneyRecipeService.map((e) => {
          return {
            label: e.name,
            value: e,
          };
        })}
        value={M_service}
        onChange={(e) => {
          console.log("🚀 ~ ChangeRecept ~ e:", e);
          setM_service(e);
          setAmount(e.value.rate);
        }}
        menuPosition="fixed"
        styles={{
          control: (styles, state) => ({
            ...styles,
            margin: 0,
            padding: 2,
            backgroundColor: "rgb(248 249 250)",
            borderColor: false
              ? "rgb(245 57 57 / 0.5)"
              : state.isFocused
              ? "rgb(59 130 246 / 0.5)"
              : state.isDisabled
              ? "rgb(233 227 255)"
              : "rgb(192 184 254)",
            opacity: state.isDisabled ? "0.5" : 1,
            borderWidth: 1.5,
            "&:hover": {
              borderColor: "none",
            },
            boxShadow: state.isFocused
              ? false
                ? "0 0 0 1px rgb(245 57 57 / 0.5)"
                : "0 0 0 1px rgb(59 130 246 / 0.5)"
              : "none",
          }),
          placeholder: (provided) => ({
            ...provided,
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "0.875rem",
            color: "#adb5bd",
            letterSpacing: "0.3px",
            userSelect: "none",
          }),
          valueContainer: (styles) => ({
            ...styles,
            userSelect: "none",
            color: "rgb(27 37 89)",
            letterSpacing: "0.3px",
          }),
        }}
      />
      {/* "approved", "pending", "cancel" */}
      <Input
        onChange={(e) => {
          setFamily(e.target.value);
        }}
        value={Family}
        label="Enter The Pax"
      />
      <div>
        <input
          onChange={(e) => setService_confirm(e.target.checked)} // Use 'checked' for checkbox
          type="checkbox"
          checked={Service_confirm} // Bind to 'checked' instead of 'value'
        />
        <label>Confirm Services</label>
      </div>
      <Input
        onChange={(e) => {
          setM_rate(e.target.value);
        }}
        value={M_rate}
        label="Enter The Rate (SGD)"
      />

      <Input
        onChange={(e) => {
          setAmount(e.target.value);
        }}
        value={Amount}
        label="Enter The Total Amount (SGD)"
      />
      <label className="pt-4">Enter Extra Description</label>
      <Textarea
        cols={2}
        onChange={(e) => {
          setM_ex_des(e.target.value);
        }}
        value={M_ex_des}
        className="block  w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
      ></Textarea>

      <div className="mt-3 flex w-full flex-wrap items-center justify-center gap-1">
        <select
          onChange={(e) => {
            setStatus(e.target.value);
          }}
          value={Status}
          className="my-2 flex-1 rounded-lg px-2 py-1 ring-2 ring-offset-teal-300 "
        >
          <option value="">Choose Status</option>
          <option value="approved">Approved</option>
          <option value="cancel">Cancel</option>
        </select>
        <select
          onChange={(e) => {
            setShow(e.target.value);
          }}
          value={Show}
          className="my-2 flex-1 rounded-lg px-2 py-1 ring-2 ring-offset-teal-300 "
        >
          <option value="">Choose Status</option>
          <option value="show">Show</option>
          <option value="hide">Hide</option>
        </select>
        <select
          onChange={(e) => {
            setM_payment_status(e.target.value);
          }}
          value={M_payment_status}
          className="my-2 flex-1 rounded-lg px-2 py-1 ring-2 ring-offset-teal-300 "
        >
          <option value="due">Due</option>
          <option value="re-fund">Re Fund</option>
          <option value="paid">Paid</option>
          <option value="canceled">Canceled</option>
          <option value="advanced">Advanced</option>
        </select>

        <Button onClick={ChangeStatus} className=" flex-1 ">
          Update
        </Button>
      </div>
      <PrintMoney guest={data} />
    </div>
  );
};

export default ChangeRecept;
