import React, { useEffect, useState } from "react";

const Model = () => {
  const [Open, setOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1000);
  }, []);
  return (
    <>
      {Open && (
        <div
          onClick={() => {
            setOpen(false);
          }}
          className="bg-black fixed top-0 z-[2000] flex h-screen w-full cursor-pointer items-center justify-center bg-gray-800/70 backdrop-blur-sm"
        >
          <img
            src="/landing/modal.jpg"
            alt="landing"
            className="mx-2 w-full rounded-md ring-1 ring-red-500 md:w-[500px]"
          />
        </div>
      )}
    </>
  );
};

export default Model;
