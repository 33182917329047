import React from "react";
import UpdateEmailConfig from "./UpdateConfig";
import Vietnam from "./Vietnam";
const EmailSetup = () => {
  return (
    <div className="relative w-full p-3 pt-10">
      <div className="grid grid-cols-1 gap-3 p-3">
        <div className="rounded-md bg-white p-3 shadow-sm">
          <h1 className="mb-2 border-b-2 border-b-gray-500/20 pb-1 text-xl font-bold">Singapore email</h1>
          <UpdateEmailConfig />
        </div>
        {/* <div className="rounded-md bg-white p-3 shadow-sm">
          <h1 className="mb-2 border-b-2 border-b-gray-500/20 pb-1 text-xl font-bold">Vietnam email</h1>

          <Vietnam />
        </div> */}
      </div>
    </div>
  );
};

export default EmailSetup;
