import axios from "axios";
import { Button } from "components/form/Button";
import exportFromJSON from "export-from-json";
import useReport from "hook/useReport";
import React, { useState } from "react";
import { toast } from "react-toastify";
import GetAllDuByMonth from "./GetAllDuByMonth";
import ServiceReport from "./ServiceReport";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getData = async (c, state) => {
  try {
    const resDb = await axios.get(`/api/report/${c}`);

    if (!resDb) {
      return toast.error("something went wrong");
    }
    const dataToSave = resDb.data.map((e) => {
      return {
        agent: JSON.parse(e.agent).username,
        "Total Paid": e.paid_count,
        "Total Due": e.due_count,
      };
    });
    if (state === "excel") {
      exportFromJSON({
        data: dataToSave,
        fileName: "Payment report By month" + new Date().toLocaleDateString(),
        exportType: exportFromJSON.types.xls,
      });
    }
    if (state === "pdf") {
    }
  } catch (error) {
    console.log("🚀 ~ file: Index.jsx:31 ~ getData ~ error:", error);
  }
};

const Report = () => {
  const [Month, SetMonth] = useState(new Date().getMonth());
  const [Year, SetYear] = useState(new Date().getFullYear());
  const { data: allAgentPayment } = useReport("getStatusAllAdmin");

  let ExportToExcel = () => {
    if (!allAgentPayment) {
      return toast.error("something went wrong");
    }
    const dataToSave = allAgentPayment.map((e) => {
      return {
        agent: JSON.parse(e.agent).username,
        "Total Paid": e.paid_count,
        "Total Due": e.due_count,
      };
    });
    exportFromJSON({
      data: dataToSave,
      fileName: "Payment report all user " + new Date().toLocaleDateString(),
      exportType: exportFromJSON.types.xls,
    });
  };
  return (
    <div className="relative grid w-full grid-cols-1 gap-3 p-2 py-5 md:grid-cols-2">
      <div>
        <h1>Download Payment report</h1>
        <Button onClick={ExportToExcel}> Download Payment Report</Button>
      </div>
      <div>
        <h1>Download Monthly Payment report</h1>
        <div className="py-2 ">
          <select
            onChange={(e) => {
              SetMonth(e.target.value);
            }}
          >
            {months.map((m, i) => {
              return <option value={m}>{m}</option>;
            })}
          </select>
          <select
            onChange={(e) => {
              SetYear(e.target.value);
            }}
          >
            <option selected={new Date().getFullYear() === 2023} value="2023">
              2023
            </option>
            <option selected={new Date().getFullYear() === 2024} value="2024">
              2024
            </option>
            <option selected={new Date().getFullYear() === 2025} value="2025">
              2025
            </option>
            <option selected={new Date().getFullYear() === 2026} value="2026">
              2026
            </option>
            <option selected={new Date().getFullYear() === 2027} value="2027">
              2027
            </option>
            <option selected={new Date().getFullYear() === 2028} value="2028">
              2028
            </option>
          </select>
        </div>
        <Button
          className="mr-5"
          onClick={() => {
            if (Month && Year) {
              getData(`getStatusAllAdminByAdmin?month=${Month}&year=${Year}`, "excel");
            } else {
              alert("Please select a valid month and year");
            }
          }}
        >
          {" "}
          Excel Payment Report
        </Button>
        <Button
          onClick={() => {
            if (Month && Year) {
              getData(`getStatusAllAdminByAdmin?month=${Month}&year=${Year}`, "pdf");
            } else {
              alert("Please select a valid month and year");
            }
          }}
        >
          {" "}
          Print Payment Report
        </Button>
      </div>
      {/* get agent monthly report  */}
      <div className="col-span-2">
        <GetAllDuByMonth />
      </div>
      <div className="col-span-2 w-full">
        <ServiceReport />
      </div>
    </div>
  );
};

export default Report;
