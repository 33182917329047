import React from "react";
import Nav from "./Nav";

const LandingLayout = ({ children }) => {
  return (
    <div>
      <Nav />
      {children}
      <>Footer</>
    </div>
  );
};

export default LandingLayout;
