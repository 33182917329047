import axios from "axios";
import { Button } from "components/form/Button";
import React, { useEffect, useState } from "react";
import AddNewAdmin from "./AddNewAdmin";
import Table from "./table";
const url = "/api/admin/all-admin";
const Index = () => {
  const [add, SetAdd] = useState(false);
  const [AllAdmin, SetAllAdmin] = useState([]);

  useEffect(() => {
    const GetDate = async () => {
      try {
        const AllAdminRes = await axios.get(url);
        SetAllAdmin(AllAdminRes.data);
      } catch (error) {
        console.log("🚀 ~ GetDate ~ error:", error);
      }
    };
    GetDate();
  }, []);

  return (
    <div className="pt-10">
      <div>
        <Button
          onClick={() => {
            SetAdd((e) => !e);
          }}
        >
          Add New Admin
        </Button>
      </div>
      <>{add && <AddNewAdmin close={SetAdd} />}</>
      <div className="mt-10">
        <Table
          colunm={[
            {
              Header: "ID",
              accessor: "id",
              Cell: ({ row }) => {
                return (
                  <p title={row.original.id} className="max-w-[40px] cursor-pointer truncate">
                    {row.original.id}
                  </p>
                );
              },
            },
            {
              Header: "Username",
              accessor: "username",
              Cell: (prop) => {
                return (
                  <div>
                    <h1 className="text-xl">{prop.row.original.username}</h1>
                  </div>
                );
              },
            },
            {
              Header: "Email",
              accessor: "email",
              Cell: (prop) => {
                return (
                  <div>
                    <h1 className="text-xl">{prop.row.original.email}</h1>
                  </div>
                );
              },
            },
            {
              Header: "Phone",
              accessor: "phone",
              Cell: (prop) => {
                return (
                  <div>
                    <a href="tel:{prop.row.original.phone}" className="text-xl">
                      {prop.row.original.phone}
                    </a>
                  </div>
                );
              },
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: (prop) => {
                return (
                  <div>
                    <p className="text-xl">{prop.row.original.status}</p>
                  </div>
                );
              },
            },
            {
              Header: "Date",
              accessor: "createdAt",
              Cell: (prop) => {
                return (
                  <div>
                    <p>
                      <span>Create: </span>
                      {new Date(prop.row.original.createdAt).toDateString()}
                    </p>
                    <p>
                      <span>Last Update: </span>
                      {new Date(prop.row.original.updateAt).toDateString()}
                    </p>
                  </div>
                );
              },
            },

            {
              Header: "Action",
              accessor: "",
              Cell: (prop) => {
                return (
                  <>
                    {+prop.row.original.status ? (
                      +prop.row.original.status !== 400 ? (
                        <div className="flex justify-center">
                          <button
                            onClick={() => {}}
                            className="flex items-center rounded bg-brand-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                          >
                            <span className="text-xl"></span> details
                          </button>
                        </div>
                      ) : (
                        <button className="ml-3 rounded bg-red-500 px-4 py-2 font-bold text-white ">Rejected</button>
                      )
                    ) : (
                      <div className="flex justify-center">
                        <button
                          onClick={() => {}}
                          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => {}}
                          className="ml-3 rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </>
                );
              },
            },
          ]}
          datas={AllAdmin || []}
        />
      </div>
    </div>
  );
};

export default Index;
