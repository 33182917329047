import axios from "axios";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAgentStore from "store/useAgent";
import AgentNavbar from "views/agent/navbar/AgentNavbar";

function Agent() {
  const { Agent, update } = useAgentStore();
  const navigate = useNavigate();

  useEffect(() => {
    const getAuth = async () => {
      try {
        const { data: agent } = await axios.get("/api/agent/info");
        update(agent);
      } catch (e) {
        console.log(e);
        navigate("/agent/Login");
      }
    };

    getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Agent ? (
    <div className="bg-[#F5F8FE] p-5 md:p-1">
      <AgentNavbar agent={Agent} />
      <div className="container mx-auto my-5">
        <Outlet />
      </div>
    </div>
  ) : (
    <h1>Loading...</h1>
  );
}

export default Agent;
