// import Checkbox from "components/checkbox";
import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { LineMdDownloadingLoop } from "../From/Index";

const GuestTable = ({ Agent, guests, date }) => {
  // const [Service, SetService] = useState(true);

  const totalDue = guests
    .filter((guest) => guest?.m_status === "approved")
    .filter((guest) => guest?.m_payment_status === "due")
    .reduce((sum, guest) => {
      return sum + ((guest.m_amount || 0 * guest.m_ex_rate || 0) * guest.m_ex_rate || 0);
    }, 0);

  const totalPaid = guests
    .filter((guest) => guest?.m_status === "approved")
    .filter((guest) => guest?.m_payment_status === "paid")
    .reduce((sum, guest) => {
      return sum + ((guest.m_amount || 0 * guest.m_ex_rate || 0) * guest.m_ex_rate || 0);
    }, 0);

  const downloadExcel = () => {
    const table = document.getElementById("table-to-excel");
    const html = table?.outerHTML;
    const blob = new Blob([html], { type: "application/vnd.ms-excel" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = "table.xls";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadExcel2 = () => {
    const table = document.getElementById("table-to-excel2");
    const html = table?.outerHTML;
    const blob = new Blob([html], { type: "application/vnd.ms-excel" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = "Confirm Service.xls";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //print
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Service Payment Statement",
  });

  return (
    <div className="overflow-x-auto">
      {/* <div>
        <p>Filter by:</p>
        <div>
          <div className="flex items-center gap-2">
            <Checkbox
              checked={Service}
              onClick={() => {
                SetService((E) => !E);
              }}
            />
            Confirmed Service
          </div>
        </div>
      </div> */}
      <div className="flex w-full items-center justify-start gap-3 py-3">
        <button onClick={downloadExcel} className=" rounded-md bg-blueSecondary p-2 text-white">
          Download in Excel
        </button>
        <button
          className=" mx-2 mt-2 flex items-center rounded-xl bg-brand-600 px-3 py-2  text-white transition-all  duration-300 hover:scale-105 hover:shadow-xl active:scale-95"
          onClick={handlePrint}
        >
          <span className="pr-2 text-2xl">
            <LineMdDownloadingLoop />
          </span>
          Print Receipt
        </button>
      </div>
      <div ref={componentRef} className="text-black relative hidden w-full bg-white print:block">
        <table>
          <thead>
            <tr>
              <th className="relative">
                <div className="absolute top-[600px] -z-0 flex h-full w-full items-center  justify-center">
                  <img className="absolute w-3/5 opacity-20" src="/joy.png" alt="" />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {/* header start  */}
                <div className="relative top-6 text-center">
                  <h1 className="text-2xl font-bold">Joy Travel & Tours Pte Ltd</h1>
                  <address className="px-32 text-xs font-light">
                    199 Syed Alwi Road #02-199A Singapore City-207731, Singapore.
                  </address>
                  <div className="flex justify-center gap-2">
                    <p className=" flex items-center gap-1 text-xs font-extralight">📞 +65 91381993</p>
                    <p className=" flex items-center gap-1 text-xs font-extralight">📞 +65 81561227</p>
                    <p className="flex items-center gap-1 text-xs font-extralight">✉️ joyholidays88@gmail.com</p>
                  </div>
                </div>
                {/* Header end  */}
                <div className="absolute right-5 top-4">
                  <QRCode color="black" size={80} value={`https://wa.me/${6581561227}`} />
                </div>
                {/* logo start */}
                <img className="absolute left-1 top-5 w-20 " src={"/joy.png"} alt="" />

                {/* logo end */}

                <div className="h-[100px]">
                  <h1 className="mt-9 w-full text-center text-2xl font-bold">Service Payment Statement</h1>
                  <div>
                    <p>
                      Date : {new Date(date.startDate).toDateString()} To {new Date(date.endDate).toDateString()}{" "}
                    </p>
                    <p>Client Email : {Agent}</p>
                  </div>
                </div>
                <table
                  id="table-to-excel"
                  className="border-gray-800-collapse mr-2 min-w-full table-auto border border-r-2 border-gray-800 bg-white"
                >
                  <thead>
                    <tr>
                      <th className="border border-gray-800 px-1 py-1 text-sm">Voucher</th>
                      <th className="border border-gray-800 px-1 py-1 text-sm">Service</th>
                      <th className="border border-gray-800 px-1 py-1 text-sm">Exchange Rate</th>
                      <th className="border border-gray-800 px-1 py-1 text-sm">Amount</th>
                      <th className="border border-gray-800 px-1 py-1 text-sm">Pax</th>
                      <th className="border border-gray-800 px-1 py-1 text-sm">Total (SGD)</th>
                      <th className="border border-gray-800 px-1 py-1 text-sm">Total (BDT)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {guests
                      .filter((e) => e.m_amount)
                      .filter((guest) => guest?.m_status === "approved")
                      .map((guest, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td className="border border-gray-800 px-1 py-1 text-center text-sm">{guest.voucher}</td>
                            <td className="border border-gray-800 px-1 py-1 text-sm">
                              <p className="text-sm font-bold">{guest.m_service}</p>
                              <p>
                                Guest :
                                <ul className="list-inside list-disc pl-2 text-sm">
                                  <li>
                                    {guest.guest_name}-{guest.pasport_number}
                                  </li>
                                  {guest.family && guest.family.length > 0
                                    ? guest.family.map((familyMember, i) => (
                                        <li key={i}>
                                          {familyMember.guest_name} - {familyMember.pasport_number}
                                        </li>
                                      ))
                                    : ""}
                                </ul>
                              </p>
                              <p>
                                Date :{" "}
                                {new Date(guest.created_at).toLocaleDateString("eng", {
                                  month: "short",
                                  day: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </p>
                              <p className="font-bold capitalize">Status : {guest?.m_status}</p>
                              <p className="font-bold capitalize">Payment Status : {guest?.m_payment_status}</p>
                            </td>
                            <td className="border border-gray-800 px-1 py-1 text-center">{guest.m_ex_rate}</td>
                            <td className="border border-gray-800 px-1 py-1 text-center">
                              {guest.m_rate ? guest.m_rate + " SGD" : "-"}{" "}
                            </td>
                            <td className="border border-gray-800 px-1 py-1 text-center">
                              {(guest.family && guest.family.length + 1) || 1}
                            </td>
                            <td className="border border-gray-800 px-1 py-1 text-center">
                              {guest.m_amount ? guest.m_amount + " SGD" : "-"}{" "}
                            </td>
                            <td className="border border-gray-800 px-1 py-1 text-center">
                              {(guest.m_amount || 0 * guest.m_ex_rate || 0) * guest.m_ex_rate || 0} BDT
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
                {/* total amount  */}
                <div className="relative w-full py-5 text-right text-2xl">
                  <p>Total Due : {totalDue} BDT</p>
                  <p>Total Paid : {totalPaid} BDT</p>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td className="border-t-[1px] border-gray-800"></td>
            </tr>
          </tfoot>
        </table>
      </div>

      <hr />

      <table
        id="table-to-excel"
        className="border-gray-800-collapse min-w-full table-auto border border-gray-800 bg-white"
      >
        <thead>
          <tr>
            <th className="border border-gray-800 px-4 py-2">Date</th>
            <th className="border border-gray-800 px-4 py-2">Voucher</th>
            <th className="border border-gray-800 px-4 py-2">Service</th>
            <th className="border border-gray-800 px-4 py-2">Status</th>
            <th className="border border-gray-800 px-4 py-2">payment Status</th>
            <th className="border border-gray-800 px-4 py-2">Exchange Rate</th>
            <th className="border border-gray-800 px-4 py-2">Amount</th>
            <th className="border border-gray-800 px-4 py-2">Pax</th>
            <th className="border border-gray-800 px-4 py-2">Total (SGD)</th>
            <th className="border border-gray-800 px-4 py-2">Total (BDT)</th>
          </tr>
        </thead>
        <tbody>
          {guests
            .filter((e) => e.m_amount)
            .map((guest, index) => (
              <React.Fragment key={index}>
                <tr className="bg-gray-100">
                  <td className="border border-gray-800 px-4 py-2">
                    {new Date(guest.created_at).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-800 px-4 py-2">{guest.voucher}</td>
                  <td className="border border-gray-800 px-4 py-2">
                    <p className="text-sm font-bold">{guest.m_service}</p>
                    <p>
                      Guest :
                      <ul className="list-inside list-disc pl-2 text-sm">
                        <li>
                          {guest.guest_name}-{guest.pasport_number}
                        </li>
                        {guest.family && guest.family.length > 0
                          ? guest.family.map((familyMember, i) => (
                              <li key={i}>
                                {familyMember.guest_name} - {familyMember.pasport_number}
                              </li>
                            ))
                          : ""}
                      </ul>
                    </p>
                  </td>
                  <td className="border border-gray-800 px-4 py-2">{guest.m_status}</td>
                  <td className="border border-gray-800 px-4 py-2">{guest.m_payment_status}</td>
                  <td className="border border-gray-800 px-4 py-2">{guest.m_ex_rate}</td>
                  <td className="border border-gray-800 px-4 py-2">{guest.m_rate ? guest.m_rate + " SGD" : "-"} </td>
                  <td className="border border-gray-800 px-4 py-2">{(guest.family && guest.family.length + 1) || 1}</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {guest.m_amount ? guest.m_amount + " SGD" : "-"}{" "}
                  </td>
                  <td className="border border-gray-800 px-4 py-2">
                    {(guest.m_amount || 0 * guest.m_ex_rate || 0) * guest.m_ex_rate || 0} BDT
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
      {/* total amount  */}
      <div className="relative w-full py-5 text-right text-2xl">
        <p>Total Due : {totalDue} BDT</p>
        <p>Total Paid : {totalPaid} BDT</p>
      </div>
      <hr />
      <h1>Confirm Service :</h1>
      <button onClick={downloadExcel2} className=" my-3 rounded-md bg-blueSecondary p-2 text-white">
        Download in Excel Confirm Service
      </button>
      <table
        id="table-to-excel2"
        className="border-gray-800-collapse min-w-full table-auto border border-gray-800 bg-white"
      >
        <thead>
          <tr>
            <th className="border border-gray-800 px-4 py-2">Travel Date</th>
            <th className="border border-gray-800 px-4 py-2">Voucher</th>
            <th className="border border-gray-800 px-4 py-2">Service</th>
            <th className="border border-gray-800 px-4 py-2">Agent</th>
            <th className="border border-gray-800 px-4 py-2">payment Status</th>
            <th className="border border-gray-800 px-4 py-2">Exchange Rate</th>
            <th className="border border-gray-800 px-4 py-2">Amount</th>
            <th className="border border-gray-800 px-4 py-2">Pax</th>
            <th className="border border-gray-800 px-4 py-2">Total (SGD)</th>
            <th className="border border-gray-800 px-4 py-2">Total (BDT)</th>
          </tr>
        </thead>
        <tbody>
          {guests

            .filter((e) => {
              console.log(e.service_confirm);
              return e.service_confirm;
            })
            .map((guest, index) => (
              <React.Fragment key={index}>
                <tr className="bg-gray-100">
                  <td className="border border-gray-800 px-4 py-2">
                    {new Date(guest.travel_date).toLocaleDateString()}
                  </td>
                  <td className="border border-gray-800 px-4 py-2">{guest.voucher}</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {guest.m_service} <br />
                    <p style={{ whiteSpace: "pre-wrap" }}>{guest?.m_ex_des}</p>
                    Guest : <br />
                    {guest.guest_name}-{guest.pasport_number} <br />
                    {guest.family && guest.family.length > 0
                      ? guest.family.map((familyMember, i) => (
                          <>
                            {familyMember.guest_name} - {familyMember.pasport_number} <br />
                          </>
                        ))
                      : ""}
                  </td>
                  <td className="border border-gray-800 px-4 py-2">{JSON.parse(guest.agent).username}</td>
                  <td className="border border-gray-800 px-4 py-2">{guest.m_payment_status}</td>
                  <td className="border border-gray-800 px-4 py-2">{guest.m_ex_rate}</td>
                  <td className="border border-gray-800 px-4 py-2">{guest.m_rate ? guest.m_rate + " SGD" : "-"} </td>
                  <td className="border border-gray-800 px-4 py-2">{(guest.family && guest.family.length + 1) || 1}</td>
                  <td className="border border-gray-800 px-4 py-2">
                    {guest.m_amount ? guest.m_amount + " SGD" : "-"}{" "}
                  </td>
                  <td className="border border-gray-800 px-4 py-2">
                    {(guest.m_amount || 0 * guest.m_ex_rate || 0) * guest.m_ex_rate || 0} BDT
                  </td>
                </tr>
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default GuestTable;
