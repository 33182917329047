/* eslint-disable default-case */
export function numberToWord(price) {
  const sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
    dblDigit = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ],
    tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
    denom = ["", "Thousand", "Lakh", "Crore"];

  const handleTens = (dgt, prevDgt) => {
    return dgt === 0 ? "" : ` ${dgt === 1 ? dblDigit[prevDgt] : tensPlace[dgt]}`;
  };

  const handleUnits = (dgt, nxtDgt, dnm) => {
    return dgt !== 0 && nxtDgt !== 1 ? ` ${sglDigit[dgt]} ${dnm}` : dnm ? ` ${dnm}` : "";
  };

  if (isNaN(price) || price <= 0 || price.toString().length > 10) return "";

  const words = [];
  const priceStr = price.toString();
  let digit, nxtDigit;

  for (let i = 0; i < priceStr.length; i++) {
    digit = parseInt(priceStr[priceStr.length - i - 1], 10);
    nxtDigit = i < priceStr.length - 1 ? parseInt(priceStr[priceStr.length - i - 2], 10) : 0;

    switch (i) {
      case 0:
        words.push(handleUnits(digit, nxtDigit, ""));
        break;
      case 1:
        words.push(handleTens(digit, parseInt(priceStr[priceStr.length - i], 10)));
        break;
      case 2:
        words.push(
          digit !== 0
            ? ` ${sglDigit[digit]} Hundred${nxtDigit !== 0 || priceStr[priceStr.length - i - 3] !== "0" ? " and" : ""}`
            : ""
        );
        break;
      case 3:
      case 5:
      case 7:
        words.push(handleUnits(digit, nxtDigit, denom[(i - 3) / 2 + 1]));
        break;
      case 4:
      case 6:
      case 8:
        words.push(handleTens(digit, parseInt(priceStr[priceStr.length - i], 10)));
        break;
      case 9:
        words.push(
          digit !== 0
            ? ` ${sglDigit[digit]} Hundred${priceStr[priceStr.length - i] !== "0" || nxtDigit !== 0 ? " " : " Crore"}`
            : ""
        );
        break;
    }
  }

  return words.reverse().join("").trim();
}
