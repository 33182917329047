import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const AddClient = ({ close }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await toast.promise(axios.post("/api/account/add-client", data), {
        pending: "please wait...",
        success: "Client Successfully Added",
        error: "something went wrong",
      });
      close();
    } catch (error) {
      console.log("🚀 ~ onSubmit ~ error:", error);
    }
  };

  return (
    <div className="fixed left-0 top-0 z-50 h-full w-full overflow-auto bg-white/80">
      {/* from  */}
      <div className="relative mx-auto mt-10 max-w-[900px] rounded-md bg-red-500/5 p-3 shadow-md backdrop-blur-sm">
        <div className="relative flex w-full items-center justify-between border-b-2 pb-2">
          <h1 className="text-xl">Add Client</h1>
          <button onClick={close} className="rounded-sm bg-red-500 px-2 py-1 text-white">
            close
          </button>
        </div>
        {/* from  */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative grid w-full  grid-cols-3 gap-3">
            {/* c name start */}
            <div className="flex flex-col gap-1">
              <label title={"unit"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
                Company Name
              </label>
              <input
                {...register("cname", { required: "company name is require" })}
                className={
                  "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
                }
                placeholder={"🏢 Type Here "}
                type={"text"}
              />
              {errors.cname && <span className="text-xs text-red-500">{errors.cname.message}</span>}
            </div>
            {/* c name end  */}
            {/* client name start */}
            <div className="flex flex-col gap-1">
              <label title={"unit"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
                Client Name
              </label>
              <input
                {...register("client", { required: "Client name is require" })}
                className={
                  "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
                }
                placeholder={"👤 Type Here "}
                type={"text"}
              />
              {errors.client && <span className="text-xs text-red-500">{errors.client.message}</span>}
            </div>
            {/* c name end  */}
            {/* Phone start */}
            <div className="flex flex-col gap-1">
              <label title={"unit"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
                Phone Number
              </label>
              <input
                {...register("phone", { required: "Phone is require" })}
                className={
                  "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
                }
                placeholder={"📞 Type Here "}
                type={"text"}
              />
              {errors.phone && <span className="text-xs text-red-500">{errors.phone.message}</span>}
            </div>
            {/* Phone end  */}
            {/* email start */}
            <div className="flex flex-col gap-1">
              <label title={"unit"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
                Email Address
              </label>
              <input
                {...register("email", { required: "Email is require" })}
                className={
                  "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
                }
                placeholder={"✉️Type Here "}
                type={"text"}
              />
              {errors.email && <span className="text-xs text-red-500">{errors.email.message}</span>}
            </div>
            {/* email end  */}
            {/* email start */}
            <div className="col-span-2 flex flex-col gap-1">
              <label title={"unit"} className={"line-clamp-1 text-base font-medium text-gray-800"}>
                Address
              </label>
              <input
                {...register("address", { required: "Address is require" })}
                className={
                  "block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
                }
                placeholder={"🌎 Type Here "}
                type={"text"}
              />
              {errors.address && <span className="text-xs text-red-500">{errors.address.message}</span>}
            </div>
            {/* email end  */}
            <button className="mt-3 rounded-md bg-green-500 px-3 py-1 text-white hover:scale-105 ">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddClient;
