import axios from "axios";
import { Input } from "components/form/Input";
import React, { useEffect, useState } from "react";
import NormalSelect from "react-select";
import { toast } from "react-toastify";

const UpdateEmailConfig = () => {
  // eslint-disable-next-line no-unused-vars
  const [error, SetError] = useState(false);
  const [data, setData] = useState([]);
  const [selectedEmail, SetSelectedEmail] = useState("");

  const [To, SetTo] = useState("");
  const [Cc, SetCc] = useState("");
  const [Bcc, SetBcc] = useState("");

  const getAll = async () => {
    try {
      const resDB = await axios.get("/api/email/all");
      setData(resDB.data);
    } catch (error) {
      console.log("🚀 ~ file: UpdateConfig.jsx:22 ~ getAll ~ error:", error);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  // update the email configuration
  // eslint-disable-next-line no-unused-vars
  const updateConfig = async () => {
    try {
      const data = {
        country: selectedEmail,
        to: To,
        cc: Cc,
        bcc: Bcc,
      };
      console.log("🚀 ~ file: UpdateConfig.jsx:36 ~ updateConfig ~ data:", data);

      const ServerRes = await toast.promise(axios.post("/api/email/update", data), {
        pending: "please wait ...",
        success: "list updated email addresses",
        error: "something went wrong!!",
      });

      console.log("🚀 ~ file: UpdateConfig.jsx:42 ~ updateConfig ~ ServerRes:", ServerRes);
    } catch (error) {
      console.log("🚀 ~ file: UpdateConfig.jsx:36 ~ updateConfig ~ error:", error);
    }
  };

  return (
    <div className="grid gap-5">
      <span>Select the Country</span>
      <NormalSelect
        options={[
          ...data.map((e) => ({
            label: e.country,
            value: e.country,
          })),
        ]}
        onChange={(e) => {
          SetSelectedEmail(e.value);
          SetTo(data.filter((x) => x.country === e.value)[0].to);
          SetCc(data.filter((x) => x.country === e.value)[0].cc);
          SetBcc(data.filter((x) => x.country === e.value)[0].bcc);
        }}
        value={{
          label: selectedEmail,
          value: selectedEmail,
        }}
        menuPosition="fixed"
        styles={{
          control: (styles, state) => ({
            ...styles,
            margin: 0,
            padding: 2,
            backgroundColor: "rgb(248 249 250)",
            borderColor: error
              ? "rgb(245 57 57 / 0.5)"
              : state.isFocused
              ? "rgb(59 130 246 / 0.5)"
              : state.isDisabled
              ? "rgb(233 227 255)"
              : "rgb(192 184 254)",
            borderWidth: 1.5,
            opacity: state.isDisabled ? "0.5" : 1,
            "&:hover": {
              borderColor: "none",
            },
            boxShadow: state.isFocused
              ? error
                ? "0 0 0 1px rgb(245 57 57 / 0.5)"
                : "0 0 0 1px rgb(59 130 246 / 0.5)"
              : "none",
          }),
          placeholder: (styles) => ({
            ...styles,
            whiteSpace: "nowrap",
            fontSize: "0.875rem",
            overflow: "hidden",
            color: "#adb5bd",
            letterSpacing: "0.3px",
            userSelect: "none",
          }),
          valueContainer: (styles) => ({
            ...styles,
            userSelect: "none",
            color: "rgb(27 37 89)",
          }),
        }}
      />

      {/* to  */}
      <div className="flex flex-col gap-1">
        <label>To Email:</label>
        <input
          onChange={(e) => {
            SetTo(e.target.value);
          }}
          value={To}
          className="block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
          placeholder={"Type email address"}
          type={"email"}
        />
      </div>
      {/* to  */}
      {/* cc  */}
      <div className="flex flex-col gap-1">
        <label>CC Email:</label>
        <input
          onChange={(e) => {
            SetCc(e.target.value);
          }}
          value={Cc}
          className="block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
          placeholder={"Type email address Type email address separate by comma "}
          type={"email"}
        />
      </div>
      {/* cc  */}
      {/* bcc  */}
      <div className="flex flex-col gap-1">
        <label>Bcc Email:</label>
        <input
          onChange={(e) => {
            SetBcc(e.target.value);
          }}
          value={Bcc}
          className="block h-10 w-full rounded border-none bg-gray-50 p-2.5 text-sm text-gray-900 outline-none ring-1 ring-brand-100  focus:ring-2 focus:ring-blue-500/50 disabled:opacity-50 disabled:ring-brand-50"
          placeholder={"Type email address separate by comma "}
          type={"email"}
        />
      </div>
      {/* bcc  */}
      <Input label="Bcc list" />
      <button
        className="w-full rounded-md bg-blue-500 p-3 text-white transition-all duration-300 hover:shadow-md"
        onClick={updateConfig}
      >
        Update
      </button>
    </div>
  );
};

export default UpdateEmailConfig;
