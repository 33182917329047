import LandingLayout from "layouts/Landing/Landing.layout";
import React from "react";
// import Exclusive from "./Home/Exclusive";
import Hero from "./Home/Hero";
import Model from "./Home/model";
// import ServiceBox from "./Home/ServiceBox";

const Landing = () => {
  return (
    <LandingLayout>
      <Model />
      <Hero />
      {/* <ServiceBox /> */}
      {/* <Exclusive /> */}
    </LandingLayout>
  );
};

export default Landing;
