import axios from "axios";
import { Button } from "components/form/Button";
import useAgent from "hook/UseAgent";
import React, { useEffect, useState } from "react";
import NormalSelect from "react-select";
import Datepicker from "react-tailwindcss-datepicker";
import { toast } from "react-toastify";
import ModelReport from "./ModelReport";

const GetAllDuByMonth = () => {
  // eslint-disable-next-line no-unused-vars
  const { data: AllAgent, loading } = useAgent();
  const [Agent, SetAgent] = useState(null);
  const [AgentData, SetAgentData] = useState([]);

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    const getdata = async () => {
      try {
        const res = await axios.get("/api/user/get-all-user");
        SetAgentData(res.data);
      } catch (error) {}
    };
    getdata();
  }, []);

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };
  const [FilterData, SetFilterData] = useState();

  const Filter = async () => {
    try {
      const response = await toast.promise(
        axios.post("/api/admin/filter-loi-by-agent", {
          dateBefore: value.endDate,
          dateAfter: value.startDate,
          email: Agent,
        }),
        {
          loading: "Loading...",
          success: "Success!",
          error: "Error!",
        }
      );
      SetFilterData(response.data);
    } catch (error) {
      console.log("🚀 ~ file: FilterByAgent.jsx:24 ~ Filter ~ error:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
      <div className="relative mt-10 w-full rounded-md bg-white p-3 shadow-md">
        <div>
          <span>Select Agent:*</span>
          <NormalSelect
            onChange={(e) => SetAgent(e.value)}
            options={[
              ...AllAgent.map((e) => {
                return {
                  value: e.email,
                  label: e.email,
                };
              }),
            ]}
            menuPosition="fixed"
            styles={{
              control: (styles, state) => ({
                ...styles,
                margin: 0,
                padding: 2,
                backgroundColor: "rgb(248 249 250)",
                borderColor: false
                  ? "rgb(245 57 57 / 0.5)"
                  : state.isFocused
                  ? "rgb(59 130 246 / 0.5)"
                  : state.isDisabled
                  ? "rgb(233 227 255)"
                  : "rgb(192 184 254)",
                borderWidth: 1.5,
                opacity: state.isDisabled ? "0.5" : 1,
                "&:hover": {
                  borderColor: "none",
                },
                boxShadow: state.isFocused
                  ? false
                    ? "0 0 0 1px rgb(245 57 57 / 0.5)"
                    : "0 0 0 1px rgb(59 130 246 / 0.5)"
                  : "none",
              }),
              placeholder: (styles) => ({
                ...styles,
                whiteSpace: "nowrap",
                fontSize: "0.875rem",
                overflow: "hidden",
                color: "#adb5bd",
                letterSpacing: "0.3px",
                userSelect: "none",
              }),
              valueContainer: (styles) => ({
                ...styles,
                userSelect: "none",
                color: "rgb(27 37 89)",
              }),
            }}
          />
        </div>
        <div className="mt-3 rounded-sm ring-1 ring-blueSecondary/60">
          <span className="p-3 pb-0">Select the Time Range</span>
          <Datepicker
            classNames={"ring-2 ring-"}
            onChange={handleValueChange}
            value={value}
            showShortcuts={true}
            popoverDirection="down"
          />
        </div>
        <div className="w-full p-3">
          <Button onClick={Filter}>Filter</Button>
        </div>
        {FilterData && (
          <ModelReport
            data={FilterData}
            date={value}
            email={Agent}
            close={() => {
              SetFilterData(null);
            }}
          />
        )}
      </div>
      {/* new */}
      <div className="relative mt-10 w-full rounded-md bg-white p-3 shadow-md">
        <div>
          <span>Select User:*</span>
          <NormalSelect
            onChange={(e) => SetAgent(e.value)}
            options={[
              ...AgentData.map((e) => {
                return {
                  value: e.email,
                  label: e.email,
                };
              }),
            ]}
            menuPosition="fixed"
            styles={{
              control: (styles, state) => ({
                ...styles,
                margin: 0,
                padding: 2,
                backgroundColor: "rgb(248 249 250)",
                borderColor: false
                  ? "rgb(245 57 57 / 0.5)"
                  : state.isFocused
                  ? "rgb(59 130 246 / 0.5)"
                  : state.isDisabled
                  ? "rgb(233 227 255)"
                  : "rgb(192 184 254)",
                borderWidth: 1.5,
                opacity: state.isDisabled ? "0.5" : 1,
                "&:hover": {
                  borderColor: "none",
                },
                boxShadow: state.isFocused
                  ? false
                    ? "0 0 0 1px rgb(245 57 57 / 0.5)"
                    : "0 0 0 1px rgb(59 130 246 / 0.5)"
                  : "none",
              }),
              placeholder: (styles) => ({
                ...styles,
                whiteSpace: "nowrap",
                fontSize: "0.875rem",
                overflow: "hidden",
                color: "#adb5bd",
                letterSpacing: "0.3px",
                userSelect: "none",
              }),
              valueContainer: (styles) => ({
                ...styles,
                userSelect: "none",
                color: "rgb(27 37 89)",
              }),
            }}
          />
        </div>
        <div className="mt-3 rounded-sm ring-1 ring-blueSecondary/60">
          <span className="p-3 pb-0">Select the Time Range</span>
          <Datepicker
            classNames={"ring-2 ring-"}
            onChange={handleValueChange}
            value={value}
            showShortcuts={true}
            popoverDirection="down"
          />
        </div>
        <div className="w-full p-3">
          <Button onClick={Filter}>Filter</Button>
        </div>
        {FilterData && (
          <ModelReport
            data={FilterData}
            date={value}
            email={Agent}
            close={() => {
              SetFilterData(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GetAllDuByMonth;
