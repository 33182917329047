import axios from "axios";
import { create } from "zustand";

const getAgent = async () => {
  const { data } = await axios.get("/api/agent/info");
  return data;
};

const useAgentStore = create((set) => ({
  Agent: 0,
  update: async (input) => {
    if (input) {
      return set({
        Agent: input,
      });
    }
    try {
      const data = await getAgent();
      set((state) => ({ Agent: data }));
    } catch (error) {
      console.log("🚀 ~ file: useAgent.jsx:19 ~ update: ~ error:", error);
    }
  },
}));

export default useAgentStore;
