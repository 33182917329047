/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddNewUser = () => {
  const [Name, SetName] = useState("");
  const [Address, SetAddress] = useState("");
  const [Nid, SetNid] = useState("");
  const [Password, SetPassword] = useState("");
  const [Phone, SetPhone] = useState("");
  const [Email, SetEmail] = useState("");
  const Navigate = useNavigate();

  // post data
  const [load, setLoad] = useState(false);
  const SaveUser = async () => {
    if (load) {
      return toast("wait for response");
    }
    setLoad(true);
    if (!Name || !Email || !Address || !Nid || !Password || !Phone) {
      toast.warn("Please Fill all fields", {
        position: "top-center",
      });
      setLoad(false);
    }
    try {
      const res = await toast.promise(
        axios.post("/api/user/add-new", {
          name: Name,
          email: Email,
          address: Address,
          nid: Nid,
          password: Password,
          phone: Phone,
        }),
        {
          success: "User added successfully",
          pending: "Please wait ..",
          error: "some things went wrong !!!",
        }
      );
      Navigate("/agent/users");
    } catch (error) {
      console.log("🚀 ~ file: AddNewUser.jsx:19 ~ SaveUser ~ error:", error);
      toast.error("some Things went wrong !!");
    }
  };

  return (
    <div>
      <div className="mb-7 font-bold ">
        <h1 className="text-xl">Add new user</h1>
        <p>If you add a user, all responsibility for that user is yours</p>
      </div>
      <div className="relative grid w-full grid-cols-1 gap-3 sm:grid-cols-2">
        {/* input  */}
        <div className="relative flex w-full flex-col p-3">
          <label htmlFor="name">Name</label>
          <input
            onChange={(e) => {
              SetName(e.target.value);
            }}
            value={Name}
            type="text"
            name="name"
            id="name"
            placeholder="Type name here..."
            className="rounded-md p-2 outline-none ring-1 ring-blueSecondary focus:ring-2"
          />
        </div>
        {/* input  */}
        <div className="relative flex w-full flex-col p-3">
          <label htmlFor="Address">Address</label>
          <input
            onChange={(e) => {
              SetAddress(e.target.value);
            }}
            value={Address}
            type="text"
            name="Address"
            id="Address"
            placeholder="Type Address here..."
            className="rounded-md p-2 outline-none ring-1 ring-blueSecondary focus:ring-2"
          />
        </div>
        {/* input  */}
        <div className="relative flex w-full flex-col p-3">
          <label htmlFor="nid">NID Number</label>
          <input
            onChange={(e) => {
              SetNid(e.target.value);
            }}
            value={Nid}
            type="text"
            name="nid"
            id="nid"
            placeholder="Type NID Number here..."
            className="rounded-md p-2 outline-none ring-1 ring-blueSecondary focus:ring-2"
          />
        </div>
        {/* input  */}
        <div className="relative flex w-full flex-col p-3">
          <label htmlFor="Phone">Phone</label>
          <input
            onChange={(e) => {
              SetPhone(e.target.value);
            }}
            value={Phone}
            type="text"
            name="Phone"
            id="Phone"
            placeholder="Type Phone Number here..."
            className="rounded-md p-2 outline-none ring-1 ring-blueSecondary focus:ring-2"
          />
        </div>
        {/* input  */}
        <div className="relative flex w-full flex-col p-3">
          <label htmlFor="Email">Email</label>
          <input
            onChange={(e) => {
              SetEmail(e.target.value);
            }}
            value={Email}
            type="email"
            name="Email"
            id="Email"
            placeholder="Type Email here..."
            className="rounded-md p-2 outline-none ring-1 ring-blueSecondary focus:ring-2"
          />
        </div>
        {/* input  */}
        <div className="relative flex w-full flex-col p-3">
          <label htmlFor="Password">Set New Password</label>
          <input
            onChange={(e) => {
              SetPassword(e.target.value);
            }}
            value={Password}
            type="text"
            name="Password"
            id="Password"
            placeholder="Type Password here..."
            className="rounded-md p-2 outline-none ring-1 ring-blueSecondary focus:ring-2"
          />
        </div>
      </div>
      <div className="relative p-6 ">
        <button
          onClick={SaveUser}
          disabled={load}
          className="rounded-md p-2 outline-none ring-1 ring-blueSecondary focus:ring-2"
        >
          {load && (
            <span>
              <Loading />
            </span>
          )}
          Add new User
        </button>
      </div>
    </div>
  );
};

export default AddNewUser;

export function Loading(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
        transform="matrix(0 0 0 0 12 12)"
      >
        <animateTransform
          id="svgSpinnersPulseRings30"
          attributeName="transform"
          begin="0;svgSpinnersPulseRings32.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          type="translate"
          values="12 12;0 0"
        ></animateTransform>
        <animateTransform
          additive="sum"
          attributeName="transform"
          begin="0;svgSpinnersPulseRings32.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          type="scale"
          values="0;1"
        ></animateTransform>
        <animate
          attributeName="opacity"
          begin="0;svgSpinnersPulseRings32.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="1;0"
        ></animate>
      </path>
      <path
        fill="currentColor"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
        transform="matrix(0 0 0 0 12 12)"
      >
        <animateTransform
          id="svgSpinnersPulseRings31"
          attributeName="transform"
          begin="svgSpinnersPulseRings30.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          type="translate"
          values="12 12;0 0"
        ></animateTransform>
        <animateTransform
          additive="sum"
          attributeName="transform"
          begin="svgSpinnersPulseRings30.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          type="scale"
          values="0;1"
        ></animateTransform>
        <animate
          attributeName="opacity"
          begin="svgSpinnersPulseRings30.begin+0.4s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="1;0"
        ></animate>
      </path>
      <path
        fill="currentColor"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
        transform="matrix(0 0 0 0 12 12)"
      >
        <animateTransform
          id="svgSpinnersPulseRings32"
          attributeName="transform"
          begin="svgSpinnersPulseRings30.begin+0.8s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          type="translate"
          values="12 12;0 0"
        ></animateTransform>
        <animateTransform
          additive="sum"
          attributeName="transform"
          begin="svgSpinnersPulseRings30.begin+0.8s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          type="scale"
          values="0;1"
        ></animateTransform>
        <animate
          attributeName="opacity"
          begin="svgSpinnersPulseRings30.begin+0.8s"
          calcMode="spline"
          dur="1.2s"
          keySplines=".52,.6,.25,.99"
          values="1;0"
        ></animate>
      </path>
    </svg>
  );
}
