import Logo from "components/Landing/Logo";
import { Button } from "components/form/Button";
import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <div className="fixed top-0 z-[900] w-full bg-blue-100/80 p-3 backdrop-blur-sm">
      <div className="mx-auto flex max-w-[1500px] justify-between">
        <Logo />

        <div className="flex items-center justify-end gap-5">
          <a className="text-gray-800 hover:text-red-600" href="/#">
            Home
          </a>
          <a className="text-gray-800 hover:text-red-600" href="/#">
            Visa
          </a>
          <a className="text-gray-800 hover:text-red-600" href="/#">
            Ticket
          </a>
          <a className="text-gray-800 hover:text-red-600" href="/#">
            Hotel
          </a>
          <Link to={"/agent"}>
            <button className="flex items-center justify-center gap-3 rounded-lg !bg-red-600 px-4 py-2 text-white ring-red-300 hover:ring-4 active:scale-95 ">
              <span className="ml-2 text-2xl">
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M11.98 20v-1h6.405q.23 0 .423-.192q.192-.193.192-.423V5.615q0-.23-.192-.423Q18.615 5 18.385 5H11.98V4h6.404q.69 0 1.152.463q.463.462.463 1.152v12.77q0 .69-.462 1.152q-.463.463-1.153.463zm-.71-4.462l-.703-.719l2.32-2.319H4.019v-1h8.868l-2.32-2.32l.702-.718L14.808 12z"
                  ></path>
                </svg>
              </span>
              Login
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Nav;
