import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { LineMdDownloadingLoop } from "./TotalInfo";
import { numberToWord } from "./Utilfn";

const PrintMoney = ({ guest }) => {
  //print
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: guest.voucher + " Money Recept",
  });

  return (
    <div>
      <button
        className=" mx-2 mt-2 flex items-center rounded-xl bg-brand-600 px-3 py-2  text-white transition-all  duration-300 hover:scale-105 hover:shadow-xl active:scale-95"
        onClick={handlePrint}
      >
        <span className="pr-2 text-2xl">
          <LineMdDownloadingLoop />
        </span>
        Print Receipt
      </button>
      {/* ------------- */}
      <div>
        <div ref={componentRef} className="text-black relative hidden w-full bg-white print:block">
          {/* header start  */}
          <div className="relative top-6 text-center">
            <h1 className="text-2xl font-bold">Joy Travel & Tours Pte Ltd</h1>
            <address className="px-32 text-xs font-light">
              199 Syed Alwi Road #02-199A Singapore City-207731, Singapore.
            </address>
            <div className="flex justify-center gap-2">
              {/* <p className=" flex items-center gap-1 text-xs font-extralight">📞 +65 91381993</p> */}
              <p className=" flex items-center gap-1 text-xs font-extralight">📞 +65 81561227 (whatsApp)</p>
              {/* <p className="flex items-center gap-1 text-xs font-extralight">✉️ joyholidays88@gmail.com</p> */}
            </div>
          </div>
          {/* Header end  */}
          <div className="absolute right-5 top-4">
            <QRCode color="black" size={80} value={`https://wa.me/${6581561227}`} />
          </div>
          {/* logo start */}
          <img className="absolute left-1 top-5 w-20 " src={"/joy.png"} alt="" />
          <div className="absolute top-0 -z-0 flex h-full w-full items-center  justify-center">
            <img className="absolute h-3/5 opacity-20" src="/joy.png" alt="" />
          </div>
          {/* logo end */}

          {/* info start  */}
          <div className="text-center">
            <h1 className="mt-10 text-xl font-bold uppercase">Voucher</h1>
          </div>
          <div className="relative -top-5 flex w-full justify-between text-sm leading-[2px]">
            <div>
              <ul>
                <li>
                  <span className="text-sm font-bold">Voucher:</span> {guest.voucher}
                </li>
                {/* <li>
                  <span className="text-sm font-bold">Total Amount:</span> {guest.m_amount || 0} BDT
                </li> */}
                {/* <li className="capitalize">
                  <span className="text-sm font-bold">Payment Type:</span> Online
                </li> */}
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <span className="text-sm font-bold">Date : </span>
                  {new Date(guest.createdAt).toLocaleDateString("eng", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </li>
                {/* <li>
                  <span className="text-sm font-bold">Collected By : </span>
                  {Data?.collected_by}
                </li> */}
              </ul>
            </div>
          </div>
          <p className="relative -top-5 text-sm">
            {" "}
            <span className="font-bold capitalize">Name: </span> {guest.guest_name}
          </p>
          <p className="relative -top-5 text-sm">
            {" "}
            <span className="font-bold capitalize">Passport: </span> {guest.pasport_number}
          </p>
          <p className="relative -top-5 text-sm">
            {" "}
            <span className="font-bold capitalize">Nationality: </span> {guest.guest_country}
          </p>
          <p className="relative -top-5 text-sm">
            {" "}
            <span className="font-bold capitalize">Travel From: </span> {guest.travel_from}
          </p>
          {/* table  */}
          <table className="relative -top-5 mr-2 mt-2 min-w-full border border-gray-500 text-sm">
            <thead>
              <tr>
                <th className="border-b border-r-[1px] border-gray-500 px-4  py-1">Service</th>
                <th className="border-b border-r-[1px] border-gray-500 px-4  py-1">Purpose</th>
                <th className="border-b border-r-[1px] border-gray-500 px-4  py-1">Pax</th>
                <th className="border-b border-r-[1px] border-gray-500 px-4 py-1  text-end">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b border-r-[1px] border-gray-500 px-4 py-1  text-right">
                  <div className="flex justify-between">{guest?.m_service}</div>

                  <div className="w-full text-start" style={{ whiteSpace: "pre-wrap" }}>
                    {guest?.m_ex_des}
                  </div>
                </td>
                <td className="border-b border-r-[1px] border-gray-500 px-4 py-1  text-center">{guest.purpose}</td>
                <td className="border-[1px] border-gray-500 px-4 py-1 text-center ">{guest?.family || 1}</td>
                <td className="border-[1px] border-gray-500 px-4 py-1  text-end">
                  {guest.m_show === "show" ? guest.m_rate || 0 : 0} SGD
                </td>
              </tr>
              {/* {data?.income_details?.map((e: any, i: number) => {
                return (
                  <tr key={i}>
                    <td className="py-1 px-4 border-b text-center border-r-[1px]  border-gray-500">
                      {e.income_sub_category}
                    </td>
                    <td className="py-1 px-4 border-b text-center border-r-[1px]  border-gray-500">
                      {e.details}
                    </td>
                    <td className="py-1 px-4 border-b text-end  border-gray-500">
                      {e.amount} BDT
                    </td>
                  </tr>
                );
              })} */}

              <tr>
                <td colSpan={2} className="border-b border-r-[1px] border-gray-500 px-4  py-1  text-right">
                  <div className="flex justify-between">
                    <p>Total {numberToWord(guest.m_show === "show" ? guest.m_amount || 0 : 0)} SGD Only</p>
                    <p></p>
                  </div>
                </td>
                <td className="border-[1px] border-gray-600 px-4 py-1 text-center ">{guest?.family || 1}</td>
                <td className="border-[1px] border-gray-600 px-4 py-1  text-end">
                  {guest.m_show === "show" ? guest.m_amount || 0 : 0} SGD
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mt-0 flex w-full justify-center  font-bold ">
            The service will be provided to you after we receive the visa.
          </p>

          <p className="border-black mt-2 w-full border-t border-dashed pt-3 text-justify">
            <p className="text-lg">DISCLAIMER:</p>
            <ul className="list-outside list-disc pl-5">
              <li>
                This invitation ( LOI ) merely established that you are eligible to travel, but does not guarantee that
                you are entitled to enter Singapore. Upon arrival to Singapore, you will be inspected by a Singapore
                Immigration Entry/Exit Officer who may determine that you are inadmissible any reason under Singapore
                law.
              </li>
              <li>
                You have to confirm the service after receiving the visa. Otherwise the LOI sent by us will be legally
                invalid. Joy Travel & Tours Pte Ltd shall not be responsible for immigration or other legal issues in
                Singapore without our Travel Itinerary booking confirmation.
              </li>
              <li>
                A copy of Money Receipt should be printed on A4 size paper. The printed copy should be presented on
                arrival at Joy Travel & Tours Pte Ltd service point in Singapore.
              </li>
            </ul>
          </p>
          <div className="relative w-full">
            {guest?.service_confirm && (
              <img src="/Service-sill.png" alt="" className="absolute -top-8 right-10 h-48 w-48" />
            )}
            {!guest?.service_confirm && (
              <img src="/Service-bookin-sill.png" alt="" className="absolute -top-8 right-10 h-48 w-48" />
            )}
          </div>
        </div>
      </div>
      {/* ------------- */}
    </div>
  );
};

export default PrintMoney;
