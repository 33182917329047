import React from "react";

const Hero = () => {
  return (
    <div className="relative mb-[70px] h-screen min-h-screen  w-full bg-blue-100">
      <div className="relative flex h-screen w-full items-center justify-center overflow-hidden">
        <img
          src="/landing/map.png"
          alt="asthatrip"
          className="absolute top-0 h-full w-full object-contain opacity-20"
        />
        <div className="relative left-0 top-[0px] translate-y-[-70px] text-center">
          <h1 className="text-7xl font-bold text-red-600">Astha Trip</h1>
          <p className="font-Dancing pt-3 text-3xl tracking-wide">Join The Privileged World</p>
          <p className="font-Satisfy absolute right-[700px] top-14 rotate-[-45deg] text-6xl text-gray-700">Travel</p>
          <p className="font-Satisfy absolute -top-16 right-[400px] rotate-[45deg] text-4xl text-gray-700">Booking</p>
          <p className="font-Satisfy absolute -top-6 left-[400px] rotate-[-35deg] text-4xl text-gray-700">Plan</p>
          <p className="font-Satisfy absolute -top-24 left-[80px] rotate-[-20deg] text-4xl text-gray-700">Invitation</p>
          <p className="font-Satisfy -top-22 absolute left-[700px] rotate-[35deg] text-5xl text-gray-700">Medical</p>
        </div>
      </div>
      <div className="pointer-events-none absolute bottom-0 w-full translate-y-[70px]">
        <img src="/landing/landing-ban-1.png" alt="astha trip" className="w-full" />
      </div>
    </div>
  );
};

export default Hero;
